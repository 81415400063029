import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionButton } from '../../ui-kit/components';
import { MERCHANT_CREATE_ROUTER } from '../../utils/consts';

import styles from './Merchant.module.scss';

export const Merchant = () => {

  const lang = useSelector((state) => state.site.lang)

  const _load_async = async() => {

  }

  useEffect(() => {
    _load_async()
  },[])

  return (
    <div className={styles['main-content']}>
      
      <div className={styles['title-container']}>
        <div className={styles['title']}>{ lang['merchant_title'] }</div>
        <ActionButton className={styles['button-flex']} type="link" to={MERCHANT_CREATE_ROUTER}>{ lang['merchant_button'] }</ActionButton>
      </div>

    </div>
  );
};
