import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Nav.module.scss';
import { API_ROUTER, CONTACT_ROUTER, FEES_ROUTER, SUPPORT_ROUTER } from '../../utils/consts';
import { useSelector } from 'react-redux';

export const Nav = ({ logged }) => {

  const lang = useSelector((state) => state.site.lang)
  const [navLinks, setNavLinks] = useState([])

  useEffect(() => {

    if (logged) {
      setNavLinks([
        {
          text: lang['nav_api'],
          to: API_ROUTER,
        },
        {
          text: lang['fees'],
          to: FEES_ROUTER,
        },
        {
          text: lang['сontacts'],
          to: CONTACT_ROUTER,
        }
      ])
    } else {
      setNavLinks([
        {
          text: lang['nav_api'],
          to: API_ROUTER,
        },
        {
          text: lang['fees'],
          to: FEES_ROUTER,
        },
        {
          text: lang['сontacts'],
          to: CONTACT_ROUTER,
        }
      ])
    }


  }, [lang, logged])


  return (
    <nav className={styles.body}>
      {navLinks.map(({ to, text }) => (
        <NavLink className={styles.item} to={to}>
          {({ isActive }) => (
            <span
              className={classNames(styles.item__text, {
                [styles['item--active']]: isActive,
              })}
            >
              {text}
            </span>
          )}
        </NavLink>
      ))}
    </nav>
  );
};
