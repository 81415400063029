import { $authHost, $host } from './index.js'
import jwt_decode from 'jwt-decode'

export const setMerchant = async (title, email, url_webhook, url_success, url_failure, url_processing) => {
    const {data} = await $authHost.post('api/site/set_merchant', {
        title, email, url_webhook, url_success, url_failure, url_processing
    })
    return data
}


export const setTicket = async (subject, text, type, language_id) => {
    const {data} = await $authHost.post('api/site/set_ticket', {
        subject, text, type, language_id
    })
    return data
}

export const setStatistic = async () => {
    const statistic = localStorage.getItem('statistic')
    if (statistic == null || statistic <= (Date.now() - 24 * 60 * 60 * 1000)) {
        localStorage.setItem("statistic", Date.now())
        const {data} = await $host.get('api/site/set_statistic')
        return data
    } else {
        return {}
    }
}

export const setCommentTicket = async (id, comment, language_id) => {
    const {data} = await $authHost.post('api/site/set_comment_ticket', {
        id, comment, language_id
    })
    return data
}

export const setWithdrawal = async (amount, address, currency_id, language_id) => {
    const {data} = await $authHost.post('api/site/set_withdrawal', {
        amount, address, currency_id, language_id
    })
    return data
}

export const getTicket = async (id) => {
    const {data} = await $authHost.get('api/site/get_ticket?id=' + id)
    return data
}

export const closeTicket = async (id, language_id) => {
    const {data} = await $authHost.get('api/site/close_ticket?id=' + id + '&language_id=' + language_id)
    return data
}

export const getTickets = async () => {
    const {data} = await $authHost.get('api/site/get_tickets')
    return data
}

export const getCommnets = async (ticket_id) => {
    const {data} = await $authHost.get('api/site/get_comments?ticket_id='+ticket_id)
    return data
}

export const getOperation = async (address, language_id) => {
    const {data} = await $authHost.get('api/site/get_operation?address='+address+'&language_id='+language_id)
    return data
}

export const getTranslation = async (lang) => {
    const {data} = await $host.get('api/site/get_translation?lang='+lang)
    return data.lang
}

export const getCourseBinance = async () => {
    const {data} = await $host.get('api/site/get_course_binance')
    return data
}

export const refill = async (currency_id, amount, uid, language_id) => {
    const {data} = await $authHost.post('api/site/refill', {
        currency_id, amount, uid, language_id
    })
    return data
}

export const getHistoryPayment = async (lang) => {
    const {data} = await $authHost.post('api/site/get_history_payment',{
        lang: lang
    })
    return data
}

export const getPage = async (slug, language_id) => {
    const {data} = await $host.get('api/site/get_page?slug='+slug+'&language_id='+language_id);
    return data   
}

export const getNews = async (language_id) => {
    const {data} = await $host.get('api/site/get_news?language_id='+language_id);
    return data   
}
export const getCurrencies = async (type_payment_id = 0) => {
    const {data} = await $host.get(`api/site/get_currencies?type_payment_id=${type_payment_id}`);
    return data   
}


export const getNewsItem = async (id) => {
    const {data} = await $host.get('api/site/get_news_item?id='+id);
    return data   
}

export const getCountries = async () => {
    const {data} = await $host.get('api/site/get_countries');
    return data   
}

export const getLanguages = async () => {
    const {data} = await $host.get('api/site/get_languages');
    return data   
}


export const saveProject = async (payload) => {
    const {data} = await $authHost.post('api/site/project-save', payload)
    return data
}

export const saveBill = async (payload) => {
    const {data} = await $authHost.post('api/site/bill-save', payload)
    return data
}

export const saveWithdrawal = async (payload) => {
    const {data} = await $authHost.post('api/site/withdrawal-save', payload)
    return data
}

export const withdrawalConfirm = async (payload) => {
    const {data} = await $authHost.post('api/site/withdrawal-confirm', payload)
    return data
}


export const getPages = async (menu, language_id) => {
    const {data} = await $host.get('api/site/get-pages?menu=' + menu + "&language_id=" + language_id)
    return data
}
export const getBills = async (search) => {
    const {data} = await $authHost.get('api/site/bills?search=' + search)
    return data
}

export const getBillByCpu = async (cpu, language_id) => {
    const {data} = await $authHost.get('api/site/pay/' + cpu + "/" + language_id)
    return data
}

export const getStatusPayment = async (cpu) => {
    const {data} = await $authHost.post('api/site/pay/status', {
        cpu: cpu,
    })
    return data
}

export const changeBillStep = async (cpu, step, symbol, address) => {
    const {data} = await $authHost.post('api/site/pay/change-step', {
        cpu: cpu,
        step: step,
        symbol: symbol,
        address: address,
    })
    return data
}

export const getUserCurrencyAmountHistories = async (search) => {
    const {data} = await $authHost.get('api/site/user-currency-amount-histories?search=' + search)
    return data
}

export const getCourseAmount = async (payload) => {
    const {data} = await $host.post('api/site/pay/course-amount', payload)
    return data
}

export const getProjects = async () => {
    const {data} = await $authHost.get('api/site/projects')
    return data
}

export const getProject = async (id) => {
    const {data} = await $authHost.get('api/site/project?id=' + id)
    return data
}

export const getProjectCurrency = async (id) => {
    const {data} = await $authHost.get('api/site/project-currency?id=' + id)
    return data
}

export const getProjectCurrencies = async (project_id) => {
    const {data} = await $authHost.get('api/site/project-currencies?project_id=' + project_id)
    return data
}

export const getFullStat = async () => {
    const {data} = await $authHost.get('api/site/get-full-stat')
    return data
}
