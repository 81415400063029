import styles from './WidgetStatPayments.module.scss';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';

export const WidgetStatPayments = () => {

  const stat = useSelector((state) => state.site.stat)
  const lang = useSelector((state) => state.site.lang)

  const [options, setOptions] = useState(null)

  useEffect(() => {
    if (stat.array_categories.length > 0) {
      setOptions({
        title: null,
        subtitle: null,
        yAxis: {
          title: null
        },
        xAxis: {
          categories: stat.array_categories
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false
            },
          }
        },
        series: [
          {
            name: lang['widget_stat_payments_paid_partial'],
            data: stat.array_bill_partial_amount_hightchars
          },
          {
            name: lang['widget_stat_payments_paid'],
            data: stat.array_bill_success_amount_hightchars
          }, 
          {
            name: lang['widget_stat_payments_create_invoice'],
            data: stat.array_bill_created_amount_hightchars
          },
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }
      })
    }
  }, [stat])

  if (options == null) {
    return (
      <div className={styles['container']}>
        Loading...
      </div>
    )
  }

  return (
    <div className={styles['container']}>
      
      <div className={styles['block-title']}>{ lang['widget_stat_payments_statistic'] }</div>

      <div className={styles['item-input']}>
          
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      
      </div>
      
    </div>
  )
}