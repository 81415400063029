import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setMerchant } from '../../http/siteAPI';
import { ActionButton, Input } from '../../ui-kit/components';
import { MERCHANT_CREATE_ROUTER, MERCHANT_ROUTER } from '../../utils/consts';
import { alertDanger, alertSuccess } from '../../utils/function';

import styles from './MerchantCreatePage.module.scss';

export const MerchantCreatePage = () => {

  const dispatch = useDispatch()
  
  const lang = useSelector((state) => state.site.lang)
  const navigate = useNavigate()

  const [title, setTitle] = useState("")
  const [email, setEmail] = useState("")
  const [url_webhook, setUrlWebhook] = useState("")
  const [url_success, setUrlSuccess] = useState("")
  const [url_failure, setUrlFailure] = useState("")
  const [url_processing, setUrlProcessing] = useState("")

  const sendForm = async () => {
    try {
      const result = await setMerchant(title, email, url_webhook, url_success, url_failure, url_processing)
      alertSuccess(dispatch, result.message)
      navigate(MERCHANT_ROUTER)
    } catch (e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  const _load_async = async() => {

  }

  useEffect(() => {
    _load_async()
  },[])

  return (
    <div className={styles['main-content']}>
      
      <div className={styles['title-container']}>
        <div className={styles['title']}>{ lang['merchant_button'] }</div>
      </div>

      <br/>
      <br/>

      <div className={styles['block-data']}>
        <div><Input autocomplete="off" className={styles.input} setInputValue={(val) => setTitle(val)} label="Название магазина" dynamicLabel /></div>
        <div className={styles['block-data-div']}><Input autocomplete="off" className={styles.input} setInputValue={(val) => setEmail(val)} label="Public email support" dynamicLabel /></div>
        <div className={styles['block-data-div']}><Input autocomplete="off" className={styles.input} setInputValue={(val) => setUrlWebhook(val)} label="Webhook url for notification" dynamicLabel /></div>
        <div className={styles['block-data-div']}><Input autocomplete="off" className={styles.input} setInputValue={(val) => setUrlSuccess(val)} label="Success url" dynamicLabel /></div>
        <div className={styles['block-data-div']}><Input autocomplete="off" className={styles.input} setInputValue={(val) => setUrlFailure(val)} label="Failure url" dynamicLabel /></div>
        <div className={styles['block-data-div']}><Input autocomplete="off" className={styles.input} setInputValue={(val) => setUrlProcessing(val)} label="URL processing" dynamicLabel /></div>
        <div className={styles['block-data-div-more']}><ActionButton onClick={() => sendForm()}>Add merchant</ActionButton></div>
      </div>

    </div>
  );
};
