import { useEffect, useState } from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './CopyText.module.scss';

const CopyText = (props) => {

    const [ text, setText ] = useState('')
    const [ classCopy, setClassCopy ] = useState('fa fa-copy')

    const changeIcon = () => {
        setClassCopy('fa fa-check')
        setTimeout(() => {
            setClassCopy('fa fa-copy')
        }, 500)
    }

    useEffect(() => {
        setText(props.text)
    },[props])

    return (
        <CopyToClipboard text={text}
            onCopy={() => changeIcon()}>
            <span className={ classCopy } style={{cursor: 'pointer'}}></span>
        </CopyToClipboard>
    )
}

export default CopyText