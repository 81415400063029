import { setMerchant } from "../../http/siteAPI"
import { alertDanger, alertSuccess } from "../../utils/function"

export const actionSetMerchant = (title, email, url_webhook, url_success, url_failure, url_processing) => {
  
  return async (dispatch) => {

    try {
      
      let result = await setMerchant(title, email, url_webhook, url_success, url_failure, url_processing)
      alertSuccess(dispatch, result.message)

      //dispatch(actionSetCalculation(data))

    } catch (e) {
      alertDanger(dispatch, e.response.data.message)
    }
      
  }

}