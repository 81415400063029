import { Skeleton, Switch } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AlertComponent, LanguageMenu, Timer } from '../../components';
import { InfoModal } from '../../components/InfoModal/InfoModal.jsx';
import { changeBillStep, getBillByCpu, getCourseAmount, getCurrencies, getPages, getStatusPayment } from '../../http/siteAPI';
import { getWalletAddress } from '../../http/walletAPI';
import { actionSetCurrencies } from '../../store/actionCreators/siteActionCreator';
import { Close, Logo } from '../../ui-kit/assets';
import { InputText } from '../../ui-kit/components';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyText from '../../components/CopyText/CopyText';
import { alertDanger, openModalInfo } from '../../utils/function';
import { Spinner } from 'react-bootstrap';
import { useClickOutside } from '../../hooks';

import styles from './Pay.module.scss'
import { Button } from 'react-bootstrap';


export const Pay = () => {

  const { cpu } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const divBlock = useRef(null);
  const onClickOutside = () => setShow(false)
  useClickOutside(onClickOutside, divBlock)

  const currencies = useSelector((state) => state.site.currencies)
  const language_id = useSelector((state) => state.site.language_id)
  const lang = useSelector((state) => state.site.lang)

  const [project_currencies, setProjectCurrencies] = useState([])
  const [project_currencies_new, setProjectCurrenciesNew] = useState([])
  const [type_payment_id, setTypePaymentId] = useState(null)
  const [borderType, setBorderType] = useState("2px solid rgb(106,86,208)")
  
  const [pay, setPay] = useState({})
  
  // Валюта высталенного счета
  const [symbol, setSymbol] = useState('')
  const [symbol_currency, setSymbolCurrency] = useState('')
  const [symbol_currency_pay, setSymbolCurrencyPay] = useState('')

  // Сумма счета
  const [amount, setAmount] = useState('') 
  
  // Сумма счета в валюте пользователя
  const [course_amount, setCourseAmount] = useState('') 
  const [image, setImage] = useState('')
  const [net, setNet] = useState('')
  const [email, setEmail] = useState('')
  const [isActiveButton, setIsActiveButton] = useState(true)
  const [step, setStep] = useState(1)
  const [main_button_text, setMainButtonText] = useState(lang?.btn_pay)
  const [canSaveStepData, setCanSaveStepData] = useState(false)
  const [showLoadingIconButton, setShowLoadingIconButton] = useState(false)
  const [bill_status, setBillStatus] = useState('new')
  const [amount_need, setAmountNeed] = useState('')
  const [loading, setLoading] = useState(true)
  const [faqs, setFaqs] = useState([])
  const [comission_amount, setComissionAmount] = useState(null)
  const [itog_amount, setItogAmount] = useState(null)
  const [comission_link, setComissionLink] = useState(null)

  // Адрес кошелька в валюте выбранной пользователем
  const [address, setAddress] = useState('')
  
  const _load_async = async() => {

    try {

      let temp_currencies = await getCurrencies()
      dispatch(actionSetCurrencies(temp_currencies))  

      console.log("temp_currencies")
      console.log(temp_currencies)

      let bill = await getBillByCpu(cpu, language_id)
      setPay(bill)

      let faqs_temp = await getPages(10, language_id)
      setFaqs(faqs_temp)

      if (bill.symbol_user == "") {
        setSymbol(bill.symbol)
        setAmount(bill.amount)
      } else {
        setStep(bill.step)
        setSymbol(bill.symbol_user)
        setAddress(bill.address_symbol_user)
        setAmount(bill.amount)
      }
      setProjectCurrencies(bill.project_currencies)
      setProjectCurrenciesNew(bill.project_currencies_new)
      setTypePaymentId(bill.project_currencies_new[0].type_payment_id)
      setBillStatus(bill.status)
      setAmountNeed(bill.amount_need)
      setComissionAmount(bill.comission_amount)
      setItogAmount(bill.itog_amount)
      setComissionLink(bill.comission_link)

      setCanSaveStepData(true)

      setTimeout(() => {
        setLoading(false)
      }, 1000)

    } catch(e) {
      
      alertDanger(dispatch,e.response.data.message)

    } 

  }

  const setTypePayment = (type_payment_id) => {
    setTypePaymentId(type_payment_id)
    chooseSymbol(project_currencies_new.filter((p) => p.type_payment_id == type_payment_id)[0].currencies[0].symbol)
  }

  useEffect(() => {

    let c = findSymbolCurrency(symbol)
    if (c !== null) {
      setSymbolCurrency(c.symbol_currency)
      setImage(c.image)
      setNet(c.net)
    }

  },[symbol, currencies])


  useEffect(() => {
    let c = findSymbolCurrency(pay?.symbol)
    if (c !== null) {
      setSymbolCurrencyPay(c.symbol_currency)
    }
  }, [pay, currencies])

  const findSymbolCurrency = (s) => {
    for (const tp of currencies) {
      let currencies_tmp = tp.currencies
      for (const c of currencies_tmp) {
        if (c.symbol == s) {
          return c
        }
      }
    }
    return null
  }



  const __async_load_course_amount = async () => {
    if (symbol !== '') {
      let course_amount = await getCourseAmount({
        cpu: cpu,
        symbol: symbol,
        amount: amount,
        language_id: language_id,
      })
      setCourseAmount(course_amount)
    }
  }

  useEffect(() => {
    __async_load_course_amount()
  },[symbol])

  const changeStep = async (new_step) => {
    setLoading(true)
    window.scrollTo(0, 0)
    setTimeout(() => {
      setStep(new_step)
      setLoading(false)
    }, 1000)
  }


  useEffect(() => {

    if (bill_status == "new") {
      if (step == 1) {
        setMainButtonText(lang?.btn_pay)
      } else if (step == 2) {
        setMainButtonText(lang?.btn_payed)
      } else if (step == 3) {
        setMainButtonText(lang?.btn_search_transaction)
        setShowLoadingIconButton(false)
      }
    } else if (bill_status == "canceled" || bill_status == "success" || bill_status == 'success_process' || bill_status == 'send_transition' || bill_status == 'send_comission' || bill_status == 'compare' || bill_status == "partial") {
      setMainButtonText(lang?.btn_go_to_shop)
      if (step != 3) setStep(3)
    } 

    if (canSaveStepData) {
      changeBillStep(cpu, step, symbol, address)
    }

  },[step, address, symbol, bill_status, canSaveStepData])


  const processPayment = async () => {

    setLoading(true)

    if (step == 1) {

      try {
          
        let coin = await getWalletAddress(symbol, course_amount, cpu, language_id)
        setAddress(coin.address)
        setComissionAmount(coin.comission_amount)
        setComissionLink(coin.comission_link)
        setItogAmount(coin.itog_amount)

        changeStep(2)

      } catch (e) {
        setLoading(false)
        alertDanger(dispatch,e.response.data.message)
      }

    }

    if (step == 2) {
      changeStep(3)
    }

    if (step == 3) {
      if (bill_status == "new") {

      } else {
        if (pay?.site !== '') {
          window.location.href = pay?.site
        }
      }
    }

  }

  const [width, setWidth] = useState(0)
  const [show, setShow] = useState(false)
  const chooseCurrency = () => {
    let width = divBlock.current.getBoundingClientRect().width
    setWidth(width)
    setShow(!show)
  }

  const chooseSymbol = (smbl) => {
    setSymbol(smbl)
    setShow(false)
  }




  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    _load_async()
  }, [cpu])


  
  useEffect(() => {
    if (bill_status == "new") {
      setBorderType("1px solid rgb(106,86,208)")
    } else if (
      bill_status == "success" || 
      bill_status == "success_process" || 
      bill_status == "send_transition" ||
      bill_status == "send_comission" ||
      bill_status == "compare"
    ) {
      setBorderType("1px solid rgb(0,200,0)")
    } else if (bill_status == "partial") {
      setBorderType("1px solid rgb(50,100,100)")
    } else if (bill_status == "canceled") {
      setBorderType("1px solid red")
    }
  }, [bill_status])



  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))

  
  useEffect(() => {
    setInterval(async () => {
      if (step == 3 && bill_status !== 'canceled') {
        try {
          let bill_status_res = await getStatusPayment(cpu)
          setBillStatus(bill_status_res?.status)
          setAmountNeed(bill_status_res?.amount_need)
        } catch (e) {

        }
      }
    }, 10000)
  }, [step])


  return (
    <div className={styles['container']}>
      <div className={styles['container-header']}>
        <div className={styles['header']}>
          <div className={styles['img-container']}><img className={styles['logo']} src={Logo}/></div>
          <div className={styles['devider']}><span>|</span></div>
          <div><LanguageMenu /></div>
        </div>
      </div>

      { loading == true ? (

        <div className={styles['container-main']}>
          <div>

            <div className={styles['container-left-1']}>
              <div><Skeleton variant="rectangular" height={30} /></div>
              <div style={{marginTop: 15}}><Skeleton variant="rectangular" height={40} /></div>
            </div>

            <div className={styles['container-left-2']}>
              <Skeleton variant="rectangular" height={30} />
            </div>

            <div className={styles['container-left-3']}>
              <div className={styles['faq-title']}><Skeleton variant="rectangular" height={20} /></div>

              <div style={{marginTop: 15}}><Skeleton variant="rectangular" height={16} /></div>
              <div style={{marginTop: 5}}><Skeleton variant="rectangular" height={16} /></div>
              <div style={{marginTop: 5}}><Skeleton variant="rectangular" height={16} /></div>
              <div style={{marginTop: 5}}><Skeleton variant="rectangular" height={16} /></div>
              <div style={{marginTop: 5}}><Skeleton variant="rectangular" height={16} /></div>
              <div style={{marginTop: 5}}><Skeleton variant="rectangular" height={16} /></div>
            </div>
            
          </div>

          <div>
            <div className={styles['container-right-1']} style={{padding: 0, backgroundColor: 'white', boxShadow: "3px 3px 3px rgb(235,235,235)"}}><Skeleton variant="rectangular" height={80} /></div>
            <div className={styles['container-right-2']} style={{padding: 0, backgroundColor: 'white', boxShadow: "3px 3px 3px rgb(235,235,235)"}}><Skeleton variant="rectangular" height={120} /></div>
          </div>
        </div>

      ) : (

        <div className={styles['container-main']}>
          <div>

            { step == 1 && bill_status !== "canceled" && (
              <div>

                <div className={styles['container-left-1']}>
                  <div className={styles['title-type-payment']}>{ lang?.title_choose_type_payment }</div>
                  <div className={styles['choosed-payment-container']} ref={divBlock}>
                    <div className={styles['payment-left']} onClick={chooseCurrency}>
                      <div>
                        { image !== '' && (
                          <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                        )}
                      </div>
                      <div className={styles['data-symbol']}>{ symbol }</div>
                      <div className={styles['data-fa']}><span className={"fa fa-angle-down"}></span></div>
                    </div>
                    <div className={styles['payment-right']}>{ course_amount } { symbol_currency }</div>
                  </div>
                  
                  { show && (
                    <div style={{width: width}} className={styles['select-currency']}> 

                      { project_currencies_new.length > 0 && (

                        project_currencies_new.filter((p) => p.type_payment_id == type_payment_id)[0].currencies.map((cur) => {

                          return (
                            <div className={styles['currency-choose']} key={cur.id} onClick={() => chooseSymbol(cur.symbol)}>
                              <div>{ cur.image !== '' && <img src={require("../../" + cur.image)} alt="" className={styles['img-currency']}/> }</div>
                              <div>{ cur.name }</div>
                            </div>
                          )

                        })

                      )}

                      { /*project_currencies.length > 0 && (

                        project_currencies.map((cur) => {

                          return (
                            <div className={styles['currency-choose']} key={cur.id} onClick={() => chooseSymbol(cur.symbol)}>
                              <div>{ cur.image !== '' && <img src={require("../../" + cur.image)} alt="" className={styles['img-currency']}/> }</div>
                              <div>{ cur.name }</div>
                            </div>
                          )

                        })

                      )*/}
                    </div>
                  )}


                  <div className={styles['type-payment-block']}>
                      { project_currencies_new.map((pc) => {
                        return (
                          <div className={styles['type-payment-block-item']} onClick={() => setTypePayment(pc.type_payment_id)}>{ pc.type_payment.name }</div>
                        )
                      })}
                  </div>

                </div>
                <div className={styles['container-left-2']}>
                  <div className={styles['notification-email-container']}>
                    <div><Switch onChange={() => setChecked(!checked)} {...label} checked={checked} /></div>
                    <div className={styles['notification-email']}>{ lang?.bill_send_notification_email }</div>
                  </div>
                  { checked && (
                    <div>
                      <div>
                        <div className={styles['email-label']}>{ lang?.bill_enter_email }:</div>
                        <div>
                          <InputText className={styles.input} setInputValue={setEmail} label='' />
                        </div>
                      </div>
                    </div>              
                  )}

                </div>
                
                
              </div>
            )}


            { step == 2 && bill_status !== "canceled" && (
              <div>

                <div className={styles['container-left-1']}>

                  <div className={styles['qrcode-container']}>
                    <div className={styles['qrcode-container-img']}>{ address !== '' && <QRCodeCanvas size={120} value={address} /> }</div>
                    <div className={styles['qrcode-container-text']}>
                      { symbol == "USD BERTY" ? (
                        <>
                          <div className={styles['faq-title']}>{ lang?.bill_title_1_berty }</div>
                          <div className={styles['under-title']}>{ lang?.bill_undertitle_1_berty }</div>
                        </>
                      ) : symbol === "USD PAYEER" ? (
                        <>
                          <div className={styles['faq-title']}>{ lang?.bill_title_1_berty }</div>
                          <div className={styles['under-title']}>{ lang?.bill_undertitle_1_berty }</div>
                        </>
                      ) : (
                        <>
                          <div className={styles['faq-title']}>{ lang?.bill_title_1 }</div>
                          <div className={styles['under-title']}>{ lang?.bill_undertitle_1 }</div>
                        </>
                      ) }
                    </div>
                  </div>

                  <div className={styles['flex-space-between-border']}>
                    <div className={styles['flex-amount']}>
                      <div>{ lang?.bill_amount }</div>
                      <div>{ itog_amount } { symbol_currency }</div>
                    </div>
                    <div><CopyText text={itog_amount}/></div>
                  </div>


                  



                  { symbol === "USD BERTY" ? (

                    <div className={styles['flex-space-between-border']}>
                      <div className={styles['flex-amount']}>
                        <div>{ lang?.bill_address_link }</div>
                        <div>
                          <a target="_blank" href={ address } className={styles['a-blue']}>
                            <Button>{ lang?.bill_address_link_berty }</Button>
                          </a>
                        </div>
                      </div>
                      <div><CopyText text={address}/></div>
                    </div>

                  ) : symbol === "USD PAYEER" ? (
                    
                    <div className={styles['flex-space-between-border']}>
                      <div className={styles['flex-payeer']}>
                        <div>PAYEER</div>
                        <div>
                          <div>Для перевода средств, перейдите по ссылке и оплатите.</div>
                          <div className={styles['payeer-form']}>
                            <form method="post" action={process.env.REACT_APP_PAYEER_URL} target="_blank">
                              <input type="hidden" name="m_shop" value={process.env.REACT_APP_PAYEER_SHOP}/>
                              <input type="hidden" name="m_orderid" value={cpu}/>
                              <input type="hidden" name="m_amount" value={(course_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                              <input type="hidden" name="m_curr" value={process.env.REACT_APP_PAYEER_CURRENCY}/>
                              <input type="hidden" name="m_desc" value={btoa("Localkassa invoice #" + cpu)}/>
                              <input type="hidden" name="m_sign" value={address}/>
                              <input className={styles['process-payeer-btn']} type="submit" name="m_process" value="Перейти к оплате на Payeer" />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                  ) : (
                    
                    <div className={styles['flex-space-between-border']}>
                      <div className={styles['flex-amount']}>
                        <div>{ lang?.bill_address }</div>
                        <div>{ address }</div>
                      </div>
                      <div><CopyText text={address}/></div>
                    </div>

                  )}



                  { net !== '' && (
                    <div className={styles['flex-space-between-border']}>
                      <div className={styles['flex-amount']}>
                        <div>{ lang?.bill_net }</div>
                        <div>{ net }</div>
                      </div>
                      <div><CopyText text={net}/></div>
                    </div>  
                  )}
                  

                </div>

                
                <div className={styles['container-left-2']}>

                  <div className={styles['flex-space-between']}>
                    <div className={styles['faq-title']}>{ lang?.bill_type_payment }</div>
                    <div className={styles['change-type-button']} onClick={() => changeStep(1)}>{ lang?.bill_change_type_payment }</div>
                  </div>

                  <div className={styles['choosed-payment-container-step2']}>
                    <div className={styles['payment-left']} onClick={chooseCurrency}>
                      <div>
                        { image !== '' && (
                          <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                        )}
                      </div>
                      <div className={styles['data-symbol']}>{ symbol }</div>
                    </div>
                    <div className={styles['payment-right']}>{ course_amount } { symbol_currency }</div>
                  </div>

                </div>
                
              </div>
            )}









            { bill_status == 'canceled' && (
              <div>
                <div className={styles['container-left-1']} style={{border: borderType, paddingTop: 20, paddingBottom: 20}}>
                  <div className={styles['final-container']} style={{borderColor: 'red'}}>
                    <div className={styles['final-container-img']}>
                      <span className="fa fa-ban" style={{color: 'red'}}></span>
                    </div>
                    <div className={styles['final-container-text']}>
                      <div className={styles['faq-title']}>{ lang?.bill_title_6 }</div>
                      <div className={styles['under-title-loader']}>{ lang?.bill_undertitle_6 }</div>
                    </div>
                  </div>
                </div>
              </div>
            ) }

            { step == 3 && bill_status !== 'canceled' && (
              <div>
                
                <div className={styles['container-left-1']} style={{border: borderType, paddingTop: 20, paddingBottom: 20}}>

                  { bill_status == 'new' && (
                    <div className={styles['final-container']}>
                      <div className={styles['final-container-img']}>
                        <Spinner animation="border" role="status" className="text-primary">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                      <div className={styles['final-container-text']}>
                        <div className={styles['faq-title']}>{ lang?.bill_title_2 }</div>
                        <div className={styles['under-title-loader']}>{ lang?.bill_undertitle_2 }</div>
                      </div>
                    </div>
                  ) }

                  { (bill_status == 'success_process' || bill_status == 'send_transition' || bill_status == 'send_comission' || bill_status == 'compare') && (
                    <div className={styles['final-container']} style={{borderColor: 'green'}}>
                      <div className={styles['final-container-img']}>
                        <Spinner animation="border" role="status" className="text-success">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                      <div className={styles['final-container-text']}>
                        <div className={styles['faq-title']}>{ lang?.bill_title_3 }</div>
                        <div className={styles['under-title-loader']}>{ lang?.bill_undertitle_3 }</div>
                      </div>
                    </div>
                  ) }



                  { bill_status == 'partial' && (
                    <div className={styles['final-container']} style={{borderColor: 'green'}}>
                      <div className={styles['final-container-img']}>
                        <Spinner animation="border" role="status" className="text-success">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                      <div className={styles['final-container-text']}>
                        <div className={styles['faq-title']}>{ lang?.bill_title_4 }</div>
                        <div className={styles['under-title-loader']}><span style={{color: 'red'}}>{ lang?.bill_undertitle_4 }</span>: { amount_need } { symbol_currency }</div>
                      </div>
                    </div>
                  ) }

                  
                  { bill_status == 'success' && (
                    <div className={styles['final-container']} style={{borderColor: 'green'}}>
                      <div className={styles['final-container-img']}>
                        <span className="fa fa-check" style={{color: 'green'}}></span>
                      </div>
                      <div className={styles['final-container-text']}>
                        <div className={styles['faq-title']}>{ lang?.bill_title_5 }</div>
                      </div>
                    </div>
                  ) }

                </div>

                { bill_status !== "canceled" && (

                  <div className={styles['container-left-2']}>

                    <div className={styles['flex-space-between']}>
                      <div className={styles['faq-title']}>{ lang?.bill_title_7 }</div>
                    </div>

                    <div className={styles['choosed-payment-container-step2']}>
                      <div className={styles['payment-left']} onClick={chooseCurrency}>
                        <div>
                          { image !== '' && (
                            <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                          )}
                        </div>
                        <div className={styles['data-symbol']}>{ symbol }</div>
                      </div>
                      <div className={styles['payment-right']}>{ itog_amount } { symbol_currency }</div>
                    </div>


                    
                    <div className={styles['flex-space-between-border']}>
                      <div className={styles['flex-amount']}>
                        <div>{ lang?.bill_amount }</div>
                        <div>{ itog_amount } { symbol_currency }</div>
                      </div>
                    </div>
                    



                    { symbol === "USD BERTY" ? (

                      <div className={styles['flex-space-between-border']}>
                        <div className={styles['flex-amount']}>
                          <div>{ lang?.bill_address_link }</div>
                          <div>
                            <a target="_blank" href={ address } className={styles['a-blue']}>
                              <Button>{ lang?.bill_address_link_berty }</Button>
                            </a>
                          </div>
                        </div>
                        <div><CopyText text={address}/></div>
                      </div>

                    ) : symbol === "USD PAYEER" ? (

                      <div className={styles['flex-space-between-border']}>
                        <div className={styles['flex-payeer']}>
                          <div>PAYEER</div>
                          <div>
                            <div>Для перевода средств, перейдите по ссылке и оплатите.</div>
                            <div className={styles['payeer-form']}>
                              <form method="post" action={process.env.REACT_APP_PAYEER_URL} target="_blank">
                                <input type="hidden" name="m_shop" value={process.env.REACT_APP_PAYEER_SHOP}/>
                                <input type="hidden" name="m_orderid" value={cpu}/>
                                <input type="hidden" name="m_amount" value={(course_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/>
                                <input type="hidden" name="m_curr" value={process.env.REACT_APP_PAYEER_CURRENCY}/>
                                <input type="hidden" name="m_desc" value={btoa("Localkassa invoice #" + cpu)}/>
                                <input type="hidden" name="m_sign" value={address}/>
                                <input className={styles['process-payeer-btn']} type="submit" name="m_process" value="Перейти к оплате на Payeer" />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                    ) : (

                      <div className={styles['flex-space-between-border']}>
                        <div className={styles['flex-amount']}>
                          <div>{ lang?.bill_address }</div>
                          <div>{ address }</div>
                        </div>
                      </div>

                    )}


                    

                    { net !== '' && (
                      <div className={styles['flex-space-between-border']}>
                        <div className={styles['flex-amount']}>
                          <div>{ lang?.bill_net }</div>
                          <div>{ net }</div>
                        </div>
                      </div>  
                    )}

                  </div>                

                ) }

                
                
              </div>
            )}


            { bill_status !== "canceled" && (

              <div className={styles['container-left-3']}>
                <div className={styles['faq-title']}><span className={"fa fa-question-circle"} style={{color: 'black', fontSize: 20, paddingRight: 10}}></span> FAQ</div>
                  
                <div className={styles['p']}>{ lang?.faq_undertitle }</div>

                <ul className={styles['ul']}>
                  { faqs.map((faq) => {
                    return (
                      <li key={"faq_" + faq.id}><a href={faq.url} target="_blank">{ faq.title }</a></li>
                    )
                  })}
                </ul>
              </div>

            )}

          </div>
          <div>
            <div className={styles['container-right-1']}>
              <div className={styles['inv-title']}>INV-{cpu}</div>
              { bill_status == "new" && <div className={styles['inv-time']}>{ lang?.bill_expiration } <Timer sec={ pay?.sec }/></div> }
            </div>
            <div className={styles['container-right-1']}>
              <div className={styles['project-name']}><span className={"fa fa-laptop"} style={{color: 'white', paddingRight: 10}}></span> { pay?.project_name?.toUpperCase() }</div>
              
              { bill_status !== "canceled" && (
                <div className={styles['inv-amount-bt']}>
                  <div className={styles['inv-amount-container']}>
                    <div className={step == 1 ? styles['inv-amount-container-title'] : styles['inv-amount-container-title-small']}>{ lang?.bill_itog }</div>
                    <div className={step == 1 ? styles['inv-amount-container-symbol'] : styles['inv-amount-container-symbol-small']}>{ pay?.amount } { symbol_currency_pay }</div>
                  </div>
                  { (step == 2 || step == 3) && (
                    <>

                      { comission_amount > 0 && (
                        <div className={styles['inv-amount-container']} style={{marginTop: 10}}>
                          <div className={styles['inv-amount-container-comission-title']}>
                            Комиссия &nbsp; 
                            <span onClick={() => openModalInfo(dispatch, "comission_info_title", "comission_info_body", lang)} style={{color: 'rgb(220,220,255)', cursor: 'pointer'}} className={"fa fa-info-circle"}></span>
                          </div>
                          <div className={styles['inv-amount-container-comission-symbol']} style={{fontWeight: '400'}}>{ comission_amount } { symbol_currency }</div>
                        </div>  
                      )}
                      


                      <div className={styles['inv-amount-container']} style={{marginTop: 20}}>
                        <div className={styles['inv-amount-container-title']}>{ lang?.bill_to_pay }</div>
                        <div className={styles['inv-amount-container-symbol']} style={{fontWeight: '600'}}>{ itog_amount } { symbol_currency }</div>
                      </div>

                    </>
                  )}
                </div>
              )}

              <div style={{marginTop: bill_status == 'canceled' ? 20 : 0}}>
                <div className={styles['button']} onClick={() => processPayment()}>{ main_button_text }{ showLoadingIconButton && <Spinner size="sm" variant="secondary" animation="border" role="status"><span className="visually-hidden">...</span></Spinner> }</div>
              </div>


              { bill_status !== "canceled" && (
                <div className={styles['agreement-container']}>
                  <div><span className={"fa fa-check-square-o"} style={{color: 'white', paddingRight: 10, fontSize: 16}}></span></div>
                  <div className={styles['agreement']}>{ lang?.bill_text_1 }</div>
                </div>
              )}
              
            </div>
          </div>
        </div>

      ) }

      <AlertComponent />

      <InfoModal />

    </div>
  )
}