import { Switch } from '@mui/material';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlertComponent, LanguageMenu } from '../../components';
import { changeBillStep, getBillByCpu, getCourseAmount, getCurrencies } from '../../http/siteAPI';
import { getWalletAddress } from '../../http/walletAPI';
import { actionSetCurrencies } from '../../store/actionCreators/siteActionCreator';
import { Close, Logo } from '../../ui-kit/assets';
import { InputText } from '../../ui-kit/components';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyText from '../../components/CopyText/CopyText';
import { alertDanger } from '../../utils/function';

import styles from './Pay.module.scss'
import { Spinner } from 'react-bootstrap';

export const Pay = () => {

  const { cpu } = useParams()

  const dispatch = useDispatch()

  const divBlock = useRef(null);

  const currencies = useSelector((state) => state.site.currencies)
  const language_id = useSelector((state) => state.site.language_id)

  const [project_currencies, setProjectCurrencies] = useState([])

  const [pay, setPay] = useState({})

  // Валюта высталенного счета
  const [symbol, setSymbol] = useState('')

  // Сумма счета
  const [amount, setAmount] = useState('') 
  
  // Сумма счета в валюте пользователя
  const [course_amount, setCourseAmount] = useState('') 
  const [image, setImage] = useState('')
  const [net, setNet] = useState('')
  const [email, setEmail] = useState('')
  const [isActiveButton, setIsActiveButton] = useState(true)
  const [step, setStep] = useState(1)
  const [main_button_text, setMainButtonText] = useState('Оплатить')

  // Адрес кошелька в валюте выбранной пользователем
  const [address, setAddress] = useState('')

  // useClickOutside
  const _load_async = async() => {

    try {

      let temp_currencies = await getCurrencies()
      dispatch(actionSetCurrencies(temp_currencies))  

      let bill = await getBillByCpu(cpu)

      setPay(bill)
      setSymbol(bill.symbol)
      setAmount(bill.amount)
      setProjectCurrencies(bill.project_currencies)
  
    } catch(e) {
      
      alertDanger(dispatch,e.response.data.message)

    } 

  }

  useEffect(() => {
    if (currencies.filter((c) => c.symbol == symbol).length > 0) {
      setImage(currencies.filter((c) => c.symbol == symbol)[0].image)
      setNet(currencies.filter((c) => c.symbol == symbol)[0].net)
    }
  },[symbol, currencies])

  const __async_load_course_amount = async () => {
    if (symbol !== '') {
      let course_amount = await getCourseAmount({
        cpu: cpu,
        symbol: symbol,
        amount: amount,
        language_id: language_id,
      })
      setCourseAmount(course_amount)
    }
  }

  useEffect(() => {
    __async_load_course_amount()
  },[symbol])

  const changeStep = async (new_step) => {

    try {

      await changeBillStep(cpu, new_step, symbol)

      if (new_step == 1) {
        setMainButtonText('Оплатить')
      } else if (new_step == 2) {
        setMainButtonText('Я оплатил')
      } else if (new_step == 3) {
        setMainButtonText('Далее')
      }

      setStep(new_step)

    } catch (e) {

      alertDanger(dispatch,e.response.data.message)

    }

  }


  const processPayment = async () => {

    if (step == 1) {

      try {
          
        let coin = await getWalletAddress(symbol, course_amount, cpu, language_id)
        setAddress(coin.address)
        console.log(coin)

        changeStep(2)

      } catch (e) {
        console.log(e)
        alertDanger(dispatch,e.response.data.message)
      }

    }

    if (step == 2) {
      changeStep(3)
    }

    if (step == 3) {
      alert("Ждем подтверждение оплаты")
    }

  }

  const [width, setWidth] = useState(0)
  const [show, setShow] = useState(false)
  const chooseCurrency = () => {
    let width = divBlock.current.getBoundingClientRect().width
    setWidth(width)
    setShow(!show)
  }

  const chooseSymbol = (smbl) => {
    setSymbol(smbl)
    setShow(false)
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    _load_async()
  }, [cpu])


  /*setTimeout(() => {
    if (step == 3) {
      console.log(2222)
    }
  }, 5000)*/

  return (
    <div className={styles['container']}>
      <div className={styles['container-header']}>
        <div className={styles['header']}>
          <div className={styles['img-container']}><img className={styles['logo']} src={Logo}/></div>
          <div className={styles['devider']}><span>|</span></div>
          <div><LanguageMenu /></div>
        </div>
      </div>

      <div className={styles['container-main']}>
        <div>

          { step == 1 && (
            <div>

              <div className={styles['container-left-1']}>
                <div className={styles['title-type-payment']}>Выберите способ оплаты</div>
                <div className={styles['choosed-payment-container']} ref={divBlock}>
                  <div className={styles['payment-left']} onClick={chooseCurrency}>
                    <div>
                      { image !== '' && (
                        <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                      )}
                    </div>
                    <div className={styles['data-symbol']}>{ symbol }</div>
                    <div className={styles['data-fa']}><span class="fa fa-angle-down"></span></div>
                  </div>
                  <div className={styles['payment-right']}>{ course_amount } { symbol }</div>
                </div>
                { show && (
                  <div style={{width: width}} className={styles['select-currency']}> 
                    { project_currencies.length > 0 && (

                      project_currencies.map((cur) => {

                        return (
                          <div className={styles['currency-choose']} key={cur.id} onClick={() => chooseSymbol(cur.symbol)}>
                            <div>{ cur.image !== '' && <img src={require("../../" + cur.image)} alt="" className={styles['img-currency']}/> }</div>
                            <div>{ cur.name }</div>
                          </div>
                        )

                      })

                    )}
                  </div>
                )}
              </div>
              <div className={styles['container-left-2']}>
                <div className={styles['notification-email-container']}>
                  <div><Switch onChange={() => setChecked(!checked)} {...label} checked={checked} /></div>
                  <div className={styles['notification-email']}>Отправить уведомление на почту</div>
                </div>
                { checked && (
                  <div>
                    <div>
                      <div className={styles['email-label']}>Введите Email:</div>
                      <div>
                        <InputText className={styles.input} setInputValue={setEmail} label='' />
                      </div>
                    </div>
                  </div>              
                )}

              </div>
              
              
            </div>
          )}


          { step == 2 && (
            <div>

              <div className={styles['container-left-1']}>

                <div className={styles['qrcode-container']}>
                  <div className={styles['qrcode-container-img']}>{ address !== '' && <QRCodeCanvas size={120} value={address} /> }</div>
                  <div className={styles['qrcode-container-text']}>
                    <div className={styles['faq-title']}>Реквизиты для оплаты</div>
                    <div className={styles['under-title']}>Отправьте точную сумму по указанному адресу. После совершения оплаты нажмите на кнопку «Я оплатил» для проверки транзакции.</div>
                  </div>
                </div>

                <div className={styles['flex-space-between-border']}>
                  <div className={styles['flex-amount']}>
                    <div>Сумма</div>
                    <div>{ course_amount } { symbol }</div>
                  </div>
                  <div><CopyText text={course_amount}/></div>
                </div>
                
                <div className={styles['flex-space-between-border']}>
                  <div className={styles['flex-amount']}>
                    <div>Адрес</div>
                    <div>{ address }</div>
                  </div>
                  <div><CopyText text={address}/></div>
                </div>

                { net !== '' && (
                  <div className={styles['flex-space-between-border']}>
                    <div className={styles['flex-amount']}>
                      <div>Сеть</div>
                      <div>{ net }</div>
                    </div>
                    <div><CopyText text={net}/></div>
                  </div>  
                )}
                

              </div>

              
              <div className={styles['container-left-2']}>

                <div className={styles['flex-space-between']}>
                  <div className={styles['faq-title']}>Способ оплаты</div>
                  <div className={styles['change-type-button']} onClick={() => changeStep(1)}>Изменить способ</div>
                </div>

                <div className={styles['choosed-payment-container-step2']}>
                  <div className={styles['payment-left']} onClick={chooseCurrency}>
                    <div>
                      { image !== '' && (
                        <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                      )}
                    </div>
                    <div className={styles['data-symbol']}>{ symbol }</div>
                  </div>
                  <div className={styles['payment-right']}>{ course_amount } { symbol }</div>
                </div>

              </div>
              
            </div>
          )}











          { step == 3 && (
            <div>
              
              <div className={styles['container-left-1']} style={{border: "2px solid rgb(106,86,208)"}}>

                <div className={styles['final-container']}>
                  <div className={styles['final-container-img']}>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                  <div className={styles['final-container-text']}>
                    <div className={styles['faq-title']}>Ищем транзакцию в сети Blockchain...</div>
                    <div className={styles['under-title-loader']}>Обычно транзакции выходят в сеть в течение 15 мин</div>
                  </div>
                </div>
              
              </div>

              

              <div className={styles['container-left-2']}>

                <div className={styles['flex-space-between']}>
                  <div className={styles['faq-title']}>Информация о транзакции</div>
                </div>

                <div className={styles['choosed-payment-container-step2']}>
                  <div className={styles['payment-left']} onClick={chooseCurrency}>
                    <div>
                      { image !== '' && (
                        <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                      )}
                    </div>
                    <div className={styles['data-symbol']}>{ symbol }</div>
                  </div>
                  <div className={styles['payment-right']}>{ course_amount } { symbol }</div>
                </div>


                
                <div className={styles['flex-space-between-border']}>
                  <div className={styles['flex-amount']}>
                    <div>Сумма</div>
                    <div>{ course_amount } { symbol }</div>
                  </div>
                </div>
                
                <div className={styles['flex-space-between-border']}>
                  <div className={styles['flex-amount']}>
                    <div>Адрес</div>
                    <div>{ address }</div>
                  </div>
                </div>

                { net !== '' && (
                  <div className={styles['flex-space-between-border']}>
                    <div className={styles['flex-amount']}>
                      <div>Сеть</div>
                      <div>{ net }</div>
                    </div>
                  </div>  
                )}

              </div>
              
            </div>
          )}




          <div className={styles['container-left-3']}>
            <div className={styles['faq-title']}><span class="fa fa-question-circle" style={{color: 'black', fontSize: 20, paddingRight: 10}}></span> FAQ</div>
              
            <div className={styles['p']}>В нашей базе знаний вы можете найти ответы на самые часто задаваемые вопросы.</div>

            <ul className={styles['ul']}>
              <li>Как произвести оплату счета?</li>
              <li>Как оплатить, если у меня нет криптовалюты?</li>
              <li>С какого кошелька или биржи я могу оплатить счет?</li>
              <li>Какую сумму надо отправить?</li>
              <li>Какую сеть использовать для отправки?</li>
              <li>Сколько есть времени на оплату счета?</li>
              <li>Я отправил верную сумму, но счет не подтверждается.</li>
            </ul>
          </div>


        </div>
        <div>
          <div className={styles['container-right-1']}>
            <div className={styles['inv-title']}>INV-{cpu}</div>
            <div className={styles['inv-time']}>Счет искекает через 23:22:22</div>
          </div>
          <div className={styles['container-right-1']}>
            <div className={styles['project-name']}><span class="fa fa-laptop" style={{color: 'white', paddingRight: 10}}></span> { pay?.project_name?.toUpperCase() }</div>
            <div className={styles['inv-amount-bt']}>
                
              <div className={styles['inv-amount-container']}>
                <div className={step == 1 ? styles['inv-amount-container-title'] : styles['inv-amount-container-title-small']}>Итого</div>
                <div className={step == 1 ? styles['inv-amount-container-symbol'] : styles['inv-amount-container-symbol-small']}>{ pay?.amount } { pay?.symbol }</div>
              </div>

              { (step == 2 || step == 3) && (
                <div className={styles['inv-amount-container']} style={{marginTop: 20}}>
                  <div className={styles['inv-amount-container-title']}>К оплате</div>
                  <div className={styles['inv-amount-container-symbol']} style={{fontWeight: '600'}}>{ course_amount } { symbol }</div>
                </div>
              )}

            </div>
            <div>
              <div className={styles['button']} onClick={() => processPayment()}>{ main_button_text }</div>
            </div>
            <div className={styles['agreement-container']}>
              <div><span class="fa fa-check-square-o" style={{color: 'white', paddingRight: 10, fontSize: 16}}></span></div>
              <div className={styles['agreement']}>Нажимая «Оплатить», вы принимаете пользовательское соглашение.</div>
            </div>
          </div>
        </div>
      </div>

      <AlertComponent/>

    </div>
  )
}