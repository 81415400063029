import { Skeleton } from '@mui/material';
import styles from './WidgetStat.module.scss';

export const WidgetStat = ({ title, icon, amount, link, amountUnder, color, isLink, loading }) => {

  color = color ?? "rgb(106,86,208)"
  isLink = isLink ?? false
  loading = loading ?? false
  color = isLink ? 'white' : color

  if (loading == true) {
    return (
      <div className={isLink ? styles['widget-stat-link'] : styles['widget-stat']}>
        <div className={styles['title']}><Skeleton width={"60%"} variant="rectangular" height={16} /></div>
        <div className={styles['value']}><Skeleton variant="circular" height={30} width={30} /></div>
      </div>
    )
  }
  
  return (
    <div className={isLink ? styles['widget-stat-link'] : styles['widget-stat']}>
      <div className={styles['title']}>{ title }</div>
      <div className={styles['value']} style={{color: color}}>{ icon } { amount }</div>
      { amountUnder != undefined && <div className={styles['value-under']}>{ amountUnder }</div> }
    </div>
  )

}