
import { Input } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '../../hooks';
import { getFullStat, saveWithdrawal, withdrawalConfirm } from '../../http/siteAPI';
import { actionSetShowModalWithdrawal, actionSetStat, actionSetWithdrawalSymbol } from '../../store/actionCreators/siteActionCreator';
import { InputText } from '../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../utils/function';
import styles from './WithdrawalModal.module.scss';

export const WithdrawalModal = () => {

  const divBlock = useRef(null);
  const onClickOutside = () => setShow(false)
  useClickOutside(onClickOutside, divBlock)

  const dispatch = useDispatch()
  const show_modal_withdrawal = useSelector((state) => state.site.show_modal_withdrawal)
  const withdrawal_symbol = useSelector((state) => state.site.withdrawal_symbol)
  const currencies = useSelector((state) => state.site.currencies)
  const stat = useSelector((state) => state.site.stat)
  const language_id = useSelector((state) => state.site.language_id)
  const lang = useSelector((state) => state.site.lang)
  const [address, setAddress] = useState('')
  const [btn_text, setBtnText] = useState('Заказать вывод')
  const [btn_confirm, setBtnConfirm] = useState('Подтвердить вывод')
  const [net, setNet] = useState('')
  const [amount, setAmount] = useState('')
  const [image, setImage] = useState('')
  const [symbol, setSymbol] = useState('')
  const [width, setWidth] = useState(0)
  const [show, setShow] = useState(false)
  const [loading_effect, setLoadingEffect] = useState(false)
  const [errorAmount, setErrorAmount] = useState('')
  const [errorAddress, setErrorAddress] = useState('')
  const [amount_balance, setAmountBalance] = useState('')
  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')

  const chooseCurrency = () => {
    let width = divBlock.current.getBoundingClientRect().width
    setWidth(width)
    setShow(!show)
  }

  const chooseSymbol = (smbl) => {
    setSymbol(smbl)
    setShow(false)
  }

  const confirmWithdrawal = async () => {
    
    try {

      setBtnConfirm("Сохранение...")

      let withdrawal_confirm = await withdrawalConfirm({
        code, amount, address, symbol, language_id
      })
        
      
      // Запускаем процесс loading
      setLoadingEffect(true)

      // Запускаем обновление данных, которые изменились
      let temp_stat = await getFullStat()
      dispatch(actionSetStat(temp_stat))

      // Очищаем заполненные поля
      setAddress('')
      setAmount('')
      setCode('')
      setStep(1)
      setBtnConfirm("Заказать вывод")

      // Убираем эффект
      setLoadingEffect(false)
    
      // Закрываем модальное окно
      alertSuccess(dispatch, withdrawal_confirm.message)
      dispatch(actionSetShowModalWithdrawal(false))

    } catch (e) {

      alertDanger(dispatch,e.response.data.message)

    }

    setTimeout(() => {
      setBtnConfirm("Подтвердить вывод")
    }, 700)

  }

  const createWithdrawal = async () => {

    setErrorAmount('')
    setErrorAddress('')
    
    try {

      setBtnText("Сохранение...")

      let amount_check = Number(amount)
      if (isNaN(amount_check) || amount_check <= 0) {
        setErrorAmount("Необходимо ввести сумму")
      }

      // Проверить сумму на балансе
      if (amount_check > amount_balance) {
        console.log("amount_check = " + amount_check)
        console.log("amount_balance = " + amount_balance)
        setErrorAmount("Сумма больше, чем есть в кошельке")
      }

      // Проверить что заполнен адрес
      if (address.length == 0) {
        setErrorAddress("Необходимо заполнить адрес для вывода")
      }

      if (errorAddress == "" && errorAmount == "") {
          
        const withdrawal = await saveWithdrawal({
          amount, address, symbol, language_id
        })

        setLoadingEffect(true)

        setStep(2)

        setTimeout(() => {
          setLoadingEffect(false)
        }, 2000)

      }

    } catch(e) {

      alertDanger(dispatch,e.response.data.message)

    }

    setTimeout(() => {
      setBtnText("Заказать вывод")
    }, 700)

  }

  const handleClose = () => dispatch(actionSetShowModalWithdrawal(false))



  useEffect(() => {
    setSymbol(withdrawal_symbol)
  }, [withdrawal_symbol])


  // ПЕРЕПИСАТЬ КУСОК ИСХОДЯ ИЗ ТОГО 
  useEffect(() => {

    let temp_currencies = []
    currencies.map((type_payemnt_currencies) => {
      type_payemnt_currencies.currencies.map((c) => {
        temp_currencies.push(c);
      })
    })

    if (temp_currencies.filter((c) => c.symbol == symbol).length > 0) {
      setImage(temp_currencies.filter((c) => c.symbol == symbol)[0].image)
      setNet(temp_currencies.filter((c) => c.symbol == symbol)[0].net)

      if (stat.wallet_currency.filter((w) => w.currency.symbol == symbol).length > 0) {
        console.log("setAmountBalance")
        console.log(stat.wallet_currency.filter((w) => w.currency.symbol == symbol)[0].amount)
        setAmountBalance(stat.wallet_currency.filter((w) => w.currency.symbol == symbol)[0].amount)
      }
    }

    if (withdrawal_symbol == '' && stat.wallet_currency.length > 0) {
      let smbl = stat.wallet_currency[0].currency.symbol
      setSymbol(smbl)
    }

  },[symbol, currencies, stat])
  

  return (
    <Modal show={show_modal_withdrawal} className={styles['modal']} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title><span className="fa fa-money" style={{color: "rgb(255,125,50)"}}></span> &nbsp; Вывод средств</Modal.Title>
        <div onClick={handleClose}><span class="fa fa-times"></span></div>
      </Modal.Header>
      <Modal.Body>

        { step == 1 ? (

          <div style={{minHeight: 400}}>
            <div className={styles['title-type-payment']} style={{marginTop: 10}}>1. Выберите криптовалюту для вывода</div>
            <div className={styles['choosed-payment-container']} ref={divBlock}>
              <div className={styles['payment-left']} onClick={chooseCurrency}>
                <div>
                  { image !== '' && (
                    <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                  )}
                </div>
                <div className={styles['data-symbol']}>{ symbol }</div>
                <div className={styles['data-fa']}><span class="fa fa-angle-down"></span></div>
              </div>
              <div className={styles['payment-right']}>{ amount } { symbol }</div>
            </div>
            { show && (
              <div style={{width: width}} className={styles['select-currency']}> 
                { stat.wallet_currency.length > 0 && (
                  stat.wallet_currency.map((wallet) => {
                    return (
                      <div className={styles['currency-choose']} key={wallet.currency.currency_id} onClick={() => chooseSymbol(wallet.currency.symbol)}>
                        <div>{ wallet.currency.image !== '' && <img src={require("../../" + wallet.currency.image)} alt="" className={styles['img-currency']}/> }</div>
                        <div>{ wallet.amount_pretty } { wallet.currency.symbol }</div>
                      </div>
                    )
                  })
                )}
              </div>
            )}
            <div className={styles['amount-balance']}>Доступно для вывода: <span className={styles['amount-balance-span']} onClick={() => setAmount(amount_balance)}>{ amount_balance }</span> { symbol }</div>

            <div className={styles['title-type-payment']} style={{marginTop: 25}}>2. Введите сумму вывода</div>
            <div style={{marginTop: 15}}>
              <InputText type="text" setInputValue={setAmount} defvalue={amount} className={styles.input}  />
              { errorAmount !== "" && <div className={styles['error']}>{ errorAmount }</div>}
            </div>

            <div className={styles['title-type-payment']} style={{marginTop: 25}}>3. Введите адрес получения</div>
            <div style={{marginTop: 15}}>
              <InputText type="text" setInputValue={setAddress} defvalue={address} className={styles.input}  />
              { errorAddress !== "" && <div className={styles['error']}>{ errorAddress }</div>}
            </div>
                
            <div className={styles['title-type-payment']} style={{marginTop: 25}}>4. Информация о комиссиях</div>
            <div style={{marginTop: 15}}>
              Когда вы выводите криптовалюту, наша компания не будет брать с вас комиссию за эту операцию. Однако, важно заметить, что в некоторых случаях взимается плата за перевод (газ или комиссия сети), которая будет вычитаться из суммы, которую вы пытаетесь вывести. Обратите внимание на это, чтобы не возникло недоразумений при выводе ваших средств.
            </div>

            <div style={{marginTop: 25}}>
              <div className={styles['button']} onClick={createWithdrawal}>{ btn_text }</div>
            </div>

          </div>

        ) : (

          <div style={{minHeight: 400}}>

            { loading_effect == true ? (
              <>
                
                <h1 className={styles.title}><Skeleton width={"90%"} variant="rectangular" height={30} /></h1>
                
                <div className={styles['code-title']}><Skeleton width={"40%"} variant="rectangular" height={30} /></div>
                <div className={styles['code-title']}><Skeleton width={"50%"} variant="rectangular" height={30} /></div>

                <div className={styles['payment-info']}>
                  <div><Skeleton width={"90%"} variant="rectangular" height={20} /></div>
                  <div><Skeleton width={"80%"} variant="rectangular" height={20} /></div>
                </div>

                <div style={{marginTop: 30}}>
                  <div><Skeleton width={"40%"} variant="rectangular" height={20} /></div>
                </div>

              </>
            ) : (
              <>

                <div className={styles['code-title']}>Введите код подтерждения, отправленный на email</div>

                <div style={{marginBottom: 10, marginTop: 25}}>
                  <InputText autocomplete="off" className={styles.input} placeholder="Код подтверждения" defvalue={code} setInputValue={setCode} dynamicLabel />
                </div>

                <div style={{marginTop: 25}}>
                  <div className={styles['button']} onClick={confirmWithdrawal}>{ btn_confirm }</div>
                </div>

                <div className={styles['payment-info']}>
                  <div>Сумма: { amount } { symbol }</div>
                  <div>Адрес: { address }</div>
                </div>

                <div style={{marginTop: 30}}>
                  <div onClick={() => setStep(1)} className={styles['change-data']}>Изменить данные</div>
                </div>

              </>
            ) }

          </div>

        ) } 

        

      </Modal.Body>
    </Modal>
  )
}