import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { getBills, getCurrencies, getFullStat, getProjects } from '../../http/siteAPI';
import { actionSetBills, actionSetCurrencies, actionSetProjects, actionSetProjectsMenu, actionSetStat } from '../../store/actionCreators/siteActionCreator';
import { actionSetIsAuth, actionSetUserData } from '../../store/actionCreators/userActionCreator';
import { Logo } from '../../ui-kit/assets';
import { ADMIN_LOCALKASSA_BALANCE_ROUTER, ADMIN_USER_BALANCE_ROUTER, MAIN_ROUTER } from '../../utils/consts';
import { Menu } from '../Menu';
import styles from './SideBar.module.scss';
import { useClickOutside } from '../../hooks/useClickOutside.js'
import { useRef } from 'react';
import { WithdrawalModal } from '../WithdrawalModal';
import { LanguageMenu } from '../LanguageMenu';

export const SideBar = () => {

  const dispatch = useDispatch()
  const location = useLocation()
  const pathname = location.pathname

  const lang = useSelector((state) => state.site.lang)
  const projects = useSelector((state) => state.site.projects)
  const projectsMenu = useSelector((state) => state.site.projectsMenu)
  const isAdmin = useSelector((state) => state.user.isAdmin)

  const profileRef = useRef(null);
  const [profile, setProfile] = useState(false)
  const onClickOutside = () => setProfile(false);

  useClickOutside(onClickOutside, profileRef);

  const menu = [
    {
      name: lang['manu_main'],
      icon: 'fa-icon fa fa-home',
      active: ['/'],
      menus: [],
      url: "/",
    },
    {
      name: lang['manu_balance'],
      icon: 'fa-icon fa fa-money',
      active: ['/balance'],
      menus: [],
      url: "/balance",
    },
    {
      name: lang['manu_payments'],
      icon: 'fa-icon fa fa-cc-visa',
      active: ['/payments'],
      menus: [],
      url: "/payments",
    },
    {
      name: lang['manu_my_projects'],
      icon: 'fa-icon fa fa-server',
      active: ['/projects'],
      menus: [
        ...projectsMenu,
        {
          name: lang['manu_create_project'],
          url: "/create-project",
          active: ["/create-project"]
        }
      ],
      url: "/projects"
    },
    {
      name: lang['manu_settings'],
      icon: 'fa-icon fa fa-cog',
      active: ['/settings'],
      menus: [],
      url: "/settings",
    },
    {
      name: lang['manu_apis'],
      icon: 'fa-icon fa fa-server',
      active: ['/apis'],
      menus: [],
      url: "/apis",
    },
  ]

  /*if (isAdmin) {

    menu.push({
      name: 'Баланс магазина',
      icon: 'fa-icon fa fa-cc-visa',
      active: [ADMIN_LOCALKASSA_BALANCE_ROUTER],
      menus: [],
      url: ADMIN_LOCALKASSA_BALANCE_ROUTER,
    })

    menu.push({
      name: 'Баланс пользователей',
      icon: 'fa-icon fa fa-cc-visa',
      active: [ADMIN_USER_BALANCE_ROUTER],
      menus: [],
      url: ADMIN_USER_BALANCE_ROUTER,
    })

  }*/



  const logOut = () => {
    
    dispatch(actionSetIsAuth(false))
    dispatch(actionSetUserData({}))

    delete localStorage.token;
    delete localStorage.pass;
    delete localStorage.email;

    Navigate(MAIN_ROUTER)
    
  }


  const _load_async = async() => {
    let temp = await getProjects()
    let tempMenu = []
    temp.map((i) => {
      tempMenu.push({
        name: i.name,
        url: '/project/' + i.id,
        active: ['/project/' + i.id]
      })
    })
    dispatch(actionSetProjects(temp))
    dispatch(actionSetProjectsMenu(tempMenu))

    let temp_bill = await getBills('')
    dispatch(actionSetBills(temp_bill))

    let temp_currencies = await getCurrencies()
    dispatch(actionSetCurrencies(temp_currencies))

    let temp_stat = await getFullStat()
    dispatch(actionSetStat(temp_stat))

  }

  useEffect(() => {
    _load_async()
  }, [])

  return (
    <div className={styles['side-bar']}>
      <div className={styles['logo']}>
        <div className={styles['logo-kassa']}>
          <Link to="/">
            <img src={Logo} className={styles['logo-kassa-img']}/>
          </Link>
        </div>
        <div ref={profileRef} className={styles['logo-profile-container']} onClick={() => setProfile(!profile)}>
          <div className={styles['logo-profile']}>S</div>
          <div><span class={ profile ? "fa fa-angle-up" : "fa fa-angle-down" }></span></div>
        </div>
      </div>

      { profile && (
        <div className={styles['hidden-menu']}>
          <div>
            { menu.map((m) => {
              let active = m.active.filter((i) => i == pathname).length > 0 ? true : false
              return ( 
                <Link to={m.url}>
                  <div className={styles['menu-mobile-item']}><div className={styles['menu-item-icon']}><span class={ m.icon }></span></div> <div>{m.name}</div></div>
                </Link>
              )
            })}
          </div>
          <div onClick={logOut} className={styles['menu-mobile-item']}><div className={styles['menu-item-icon']}><span class="fa fa-sign-out"></span></div> <div>logout</div></div>
        </div>  
      )}
      

      <div className={styles['menu']}>
        { menu.map((m) => {

          let active = m.active.filter((i) => i == pathname).length > 0 ? true : false
          
          return (
            <Menu active={active} url={m.url} icon={m.icon} name={m.name} menus={m.menus}/>
          )

        })}

        <div style={{
          marginTop: 15,
          marginBottom: 15,
        }}>
          <LanguageMenu type="sidebar" />
        </div>
        
        <div className={styles['under-menu']}>
          <div className={styles['link-under-menu']}><Link to={"/page/user-agreement"}>{ lang['footer_user_agreement'] }</Link></div>
          <div className={styles['link-under-menu']}><Link to={"/page/privacy-policy"}>{ lang['footer_policy'] }</Link></div>
        </div>
      </div>

      <WithdrawalModal />

    </div>
  )
}