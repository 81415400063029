/** SELECT ACTION */
export const ACTION_SET_LANGUAGE_ID = "ACTION_SET_LANGUAGE_ID"
export const ACTION_SET_LANG = "ACTION_SET_LANG"
export const ACTION_SET_LANG_SELECTED = "ACTION_SET_LANG_SELECTED"
export const ACTION_SET_ALERT_SUCCESS = "ACTION_SET_ALERT_SUCCESS"
export const ACTION_SET_ALERT_SUCCESS_TEXT = "ACTION_SET_ALERT_SUCCESS_TEXT"
export const ACTION_SET_ALERT_DANGER = "ACTION_SET_ALERT_DANGER"
export const ACTION_SET_ALERT_DANGER_TEXT = "ACTION_SET_ALERT_DANGER_TEXT"
export const ACTION_SET_LANGUAGES = "ACTION_SET_LANGUAGES"
export const ACTION_SET_PROJECTS = "ACTION_SET_PROJECTS"
export const ACTION_SET_PROJECTS_MENU = "ACTION_SET_PROJECTS_MENU"
export const ACTION_SET_BILLS = "ACTION_SET_BILLS"
export const ACTION_SET_CURRENCIES = "ACTION_SET_CURRENCIES"
export const ACTION_SET_BILL_SEARCH = "ACTION_SET_BILL_SEARCH"
export const ACTION_SET_STAT = "ACTION_SET_STAT"
export const ACTION_SET_SHOW_MODAL_WITHDRAWAL = "ACTION_SET_SHOW_MODAL_WITHDRAWAL"
export const ACTION_SET_SHOW_MODAL_INFO = "ACTION_SET_SHOW_MODAL_INFO"
export const ACTION_SET_WITHDRAWAL_SYMBOL = "ACTION_SET_WITHDRAWAL_SYMBOL"
export const ACTION_SET_MODAL_INFO_TITLE = "ACTION_SET_MODAL_INFO_TITLE"
export const ACTION_SET_MODAL_INFO_BODY = "ACTION_SET_MODAL_INFO_BODY"
