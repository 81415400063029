
import { Input } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutside } from '../../hooks';
import { getFullStat, saveWithdrawal, withdrawalConfirm } from '../../http/siteAPI';
import { actionSetShowModalInfo, actionSetShowModalWithdrawal, actionSetStat, actionSetWithdrawalSymbol } from '../../store/actionCreators/siteActionCreator';
import { InputText } from '../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../utils/function';
import styles from './InfoModal.module.scss';

export const InfoModal = () => {

  const dispatch = useDispatch()
  const show_modal_info = useSelector((state) => state.site.show_modal_info)
  const modal_info_title = useSelector((state) => state.site.modal_info_title)
  const modal_info_body = useSelector((state) => state.site.modal_info_body)

  const handleClose = () => dispatch(actionSetShowModalInfo(false))

  return (
    <Modal show={show_modal_info} className={styles['modal']} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title><span className="fa fa-info" style={{color: "rgb(255,125,50)"}}></span> &nbsp; { modal_info_title }</Modal.Title>
        <div onClick={handleClose}><span class="fa fa-times"></span></div>
      </Modal.Header>
      <Modal.Body>
        { modal_info_body }
      </Modal.Body>
    </Modal>
  )
}