import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertDanger from '../Alert/AlertDanger/AlertDanger'
import AlertSuccess from '../Alert/AlertSuccess/AlertSuccess'
import styles from './AlertComponent.module.scss'

export const AlertComponent = () => {

  const alert_success = useSelector((state) => state.site.alert_success)
  const alert_success_text = useSelector((state) => state.site.alert_success_text)
  const alert_danger = useSelector((state) => state.site.alert_danger)
  const alert_danger_text = useSelector((state) => state.site.alert_danger_text)

  return (
    <>
      { alert_success && <AlertSuccess text={ alert_success_text }/>} 
      { alert_danger && <AlertDanger text={ alert_danger_text }/>} 
    </>
  )
}