import {
    ACTION_SET_LANGUAGE_ID,
    ACTION_SET_LANG,
    ACTION_SET_LANG_SELECTED,
    ACTION_SET_ALERT_SUCCESS,
    ACTION_SET_ALERT_SUCCESS_TEXT,
    ACTION_SET_ALERT_DANGER,
    ACTION_SET_ALERT_DANGER_TEXT,
    ACTION_SET_LANGUAGES,
    ACTION_SET_PROJECTS,
    ACTION_SET_PROJECTS_MENU,
    ACTION_SET_BILLS,
    ACTION_SET_CURRENCIES,
    ACTION_SET_BILL_SEARCH,
    ACTION_SET_STAT,
    ACTION_SET_SHOW_MODAL_WITHDRAWAL,
    ACTION_SET_WITHDRAWAL_SYMBOL,
    ACTION_SET_SHOW_MODAL_INFO,
    ACTION_SET_MODAL_INFO_TITLE,
    ACTION_SET_MODAL_INFO_BODY,
} from '../actions/SiteAction'

export const actionSetModalInfoTitle = (payload) => {
    return {
        type: ACTION_SET_MODAL_INFO_TITLE,
        payload: payload
    }
}

export const actionSetModalInfoBody = (payload) => {
    return {
        type: ACTION_SET_MODAL_INFO_BODY,
        payload: payload
    }
}

export const actionSetShowModalInfo = (payload) => {
    return {
        type: ACTION_SET_SHOW_MODAL_INFO,
        payload: payload
    }
}

export const actionSetShowModalWithdrawal = (payload) => {
    return {
        type: ACTION_SET_SHOW_MODAL_WITHDRAWAL,
        payload: payload
    }
}

export const actionSetWithdrawalSymbol = (payload) => {
    return {
        type: ACTION_SET_WITHDRAWAL_SYMBOL,
        payload: payload
    }
}


export const actionSetBills = (payload) => {
    return {
        type: ACTION_SET_BILLS,
        payload: payload
    }
}


export const actionSetStat = (payload) => {
    return {
        type: ACTION_SET_STAT,
        payload: payload
    }
}

export const actionSetBillSearch = (payload) => {
    return {
        type: ACTION_SET_BILL_SEARCH,
        payload: payload
    }
}

export const actionSetCurrencies = (payload) => {
    return {
        type: ACTION_SET_CURRENCIES,
        payload: payload
    }
}

export const actionSetProjects = (payload) => {
    return {
        type: ACTION_SET_PROJECTS,
        payload: payload
    }
}

export const actionSetProjectsMenu = (payload) => {
    return {
        type: ACTION_SET_PROJECTS_MENU,
        payload: payload
    }
}

export const actionSetLanguages = (payload) => {
    return {
        type: ACTION_SET_LANGUAGES,
        payload: payload
    }
}

export const actionSetLanguageId = (payload) => {
    return {
        type: ACTION_SET_LANGUAGE_ID,
        payload: payload
    }
}

export const actionSetLang = (payload) => {
    return {
        type: ACTION_SET_LANG,
        payload: payload
    }
}

export const actionSetLangSelected = (payload) => {
    return {
        type: ACTION_SET_LANG_SELECTED,
        payload: payload
    }
}

export const actionSetAlertSuccess = (payload) => {
    return {
        type: ACTION_SET_ALERT_SUCCESS,
        payload: payload
    }
}

export const actionSetAlertSuccessText = (payload) => {
    return {
        type: ACTION_SET_ALERT_SUCCESS_TEXT,
        payload: payload
    }
}

export const actionSetAlertDanger = (payload) => {
    return {
        type: ACTION_SET_ALERT_DANGER,
        payload: payload
    }
}

export const actionSetAlertDangerText = (payload) => {
    return {
        type: ACTION_SET_ALERT_DANGER_TEXT,
        payload: payload
    }
}