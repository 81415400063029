import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const PayPayeer = () => {

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  const navigate = useNavigate()

  const m_orderid = searchParams.get('m_orderid');

  // Перенаправление может быть вызвано внутри useEffect после монтирования компонента
  React.useEffect(() => {
    if (m_orderid) {
      navigate(`/pay/${m_orderid}`);
    }
  }, [navigate, m_orderid]);

  return (
    <div></div>
  )
  
}