import { $authHost, $host } from './index.js'
import jwt_decode from 'jwt-decode'

export const getUserBalances = async () => {
    const {data} = await $authHost.get('api/admin/balances/user')
    return data
}

export const getAdminBalances = async () => {
  const {data} = await $authHost.get('api/admin/balances/admin')
  return data
}