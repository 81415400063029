
import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { getAdminBalances } from '../../http/adminApi';
import styles from './AdminLocalkassaBalance.module.scss';

export const AdminLocalkassaBalance = () => {

  const [balances, setBalances] = useState([])

  const __load_async = async () => {
    let temp = await getAdminBalances()
    setBalances(temp)
  }

  useEffect(() => {
    __load_async()
  },[])

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>Баланс LocalKassa</div>
      <div style={{marginTop: 40, maxWidth: 300}}>
        <Table striped bordered hover size="sm">
          <tbody>
            { balances.map((b) => {
              return (
                <tr>
                  <td>{b.symbol}</td>
                  <td>{b.balance} {b.symbol}</td>
                </tr>
              )
            })}
          </tbody>

        </Table>
      </div>

    </div>
  )
}