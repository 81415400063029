import { Skeleton } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUserCurrencyAmountHistories } from '../../http/siteAPI'
import { InputText } from '../../ui-kit/components'
import styles from './WidgetHistoryPayment.module.scss'
export const WidgetHistoryPayment = () => {

  const [search, setSearch] = useState("")
  const currencies = useSelector((state) => state.site.currencies)
  const projects = useSelector((state) => state.site.projects)
  const bills = useSelector((state) => state.site.bills)
  const lang = useSelector((state) => state.site.lang)
  const [loading, setLoading] = useState(true)
  const [hist, sethist] = useState([])
  const show_modal_withdrawal = useSelector((state) => state.site.show_modal_withdrawal)

  const __async_load = async() => {

    let temp_hist = await getUserCurrencyAmountHistories(search)
    sethist(temp_hist)

    setTimeout(() => {
      setLoading(false)
    }, 1000)

  }

  useEffect(() => {
    if (show_modal_withdrawal == false) {
      __async_load()
    }
  }, [search, show_modal_withdrawal])



  const historyStatuses = {
    bill: lang['widget_history_payment_status_payment'],
    withdrawal: lang['widget_history_payment_status_withdraw'],
    refill: lang['widget_history_payment_status_deposit'],
    move: lang['widget_history_payment_status_exchange'],
    comission_bill: lang['widget_history_payment_status_comission'],
  }

  let arr = []
  for (let i = 0; i < 5; i++) {
    arr.push(i)
  }

  if (loading) {
    return (

      <div className={styles['container']}>
        <div className={styles['flex-container']}>
          <div className={styles['block-title']}><Skeleton variant="rectangular" width={100} height={20} /></div>
          <div className={styles['input-container']}>
            <Skeleton variant="rectangular" width={100} height={20} />
          </div>
        </div>
        <div className={styles['table-bill-container']}>
          <table className={styles['table-bill']}>
            <tbody>
              { arr.map((i) => {
                return (
                  <tr className={styles['table-bill-thead']} key={"temp_" + i}>
                    <td><Skeleton variant="rectangular" height={20} /></td>
                    <td><Skeleton variant="rectangular" height={20} /></td>
                    <td><Skeleton variant="rectangular" height={20} /></td>
                    <td style={{textAlign: 'center'}}><Skeleton variant="rectangular" height={20} /></td>
                    <td style={{textAlign: 'center'}}><Skeleton variant="rectangular" height={20} /></td>
                    <td style={{textAlign: 'center'}}><Skeleton variant="rectangular" height={20} /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      
    )
  }

  return (
    <div className={styles['container']}>
      <div className={styles['flex-container']}>
        <div className={styles['block-title']}>{ lang['widget_history_payment_title'] }</div>
        <div className={styles['input-container']}>
          <InputText label={ lang['widget_history_payment_search'] } defvalue={search} setInputValue={search} />
        </div>
      </div>
      <div className={styles['table-bill-container']}>
        <table className={styles['table-bill']}>

          <thead>

            <tr className={styles['table-bill-thead']}>
              <td>{ lang['widget_history_payment_type_operation'] }</td>
              <td>{ lang['widget_history_payment_project'] }</td>
              <td>{ lang['widget_history_payment_amount'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['widget_history_payment_valute'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['widget_history_payment_bill_id'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['widget_history_payment_date'] }</td>
            </tr>

          </thead>

          <tbody>

            { hist.map((h) => {

              let image = 'ui-kit/assets/dolary.png'
              if (h.symbol !== "USD" && currencies.filter((c) => c.symbol == h.symbol).length > 0) {
                image = currencies.filter((c) => c.symbol == h.symbol)[0].image
              }

              return (
                <tr key={ h.id }>
                  <td><div className={styles[h.type]}>{ historyStatuses[h.type] }</div></td>
                  <td>{ h.project_id != 0 && projects.length > 0 && projects.filter((p) => h.project_id == p.id)[0].name }</td>
                  <td>{ h.amount } { h.symbol }</td>
                  <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                    <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                  </td>
                  <td style={{textAlign: 'center'}}>{ h.bill_id != 0 && bills.length > 0 && bills.filter((b) => h.bill_id == b.id)[0].cpu }</td>
                  <td style={{textAlign: 'center'}}>{ h.date }</td>
                </tr>
              )
            })}

          </tbody>

        </table>

        { hist.length == 0 && <div className={styles['no-bills']}>{ lang['widget_history_payment_no_data'] }</div>}
      </div>
    </div>
  )
}