import classNames from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../hooks';

import { ReactComponent as Logo } from '../../ui-kit/assets/Logo.svg';
import { API_ROUTER, CONTACT_ROUTER, FEES_ROUTER, NEWS_ROUTER } from '../../utils/consts';
import { WithdrawalModal } from '../WithdrawalModal';
import styles from './Footer.module.scss';

const pagesToShowFooterOnMobile = {
  '/': true,
};

const loginPages = {
  '/sign-up': true,
  '/sign-in': true,
  '/password-restore': true,
  '/restore-email-sent': true,
};

export const Footer = () => {

  const { isMobile } = useWindowSize();
  const location = useLocation();
  const showOnMobile = pagesToShowFooterOnMobile[location.pathname];
  const isOnLoginPage = loginPages[location.pathname];

  const lang = useSelector((state) => state.site.lang)

  return (
    (isMobile && !showOnMobile) ||
    (!isOnLoginPage && (
      <footer className={styles.footer}>

        <div className={classNames(styles.item, styles['logo-block'])}>
          <div style={{marginBottom: 15, fontSize: 22}}>LocalKassa</div>
          <p className={styles.text}>
            Copyright © 2022
            <br />
          </p>
          <p className={classNames(styles.text, styles['text--small'])}>
            LocalKassa.com. All rights reserved. LocalKassa is a trademark of LocalKassa Finance
            LTD
          </p>
        </div>
        {!isMobile ? (
          <>
            <div className={styles.item}>
              <h6 className={styles.title}>{ lang['footer_title_1'] }</h6>
              <Link className={styles.text} to={CONTACT_ROUTER}>
                { lang['footer_title_1_2'] }
              </Link>
            </div>
            <div className={styles.item}>
              <h6 className={styles.title}>{ lang['footer_title_2'] }</h6>
              <Link className={styles.text} to={API_ROUTER}>
                { lang['footer_title_2_1'] }
              </Link>
            </div>
            <div className={styles.item}>
              <h6 className={styles.title}>{ lang['footer_title_3'] }</h6>
              <Link className={styles.text} to={FEES_ROUTER}>
                { lang['footer_title_3_1'] }
              </Link>
            </div>
            <div className={styles.item}>
              <h6 className={styles.title}>{ lang['footer_title_4'] }</h6>
              <Link className={styles.text} to='/page/how-it-works'>
                { lang['footer_title_4_1'] }
              </Link>
              <Link className={styles.text} to='/page/user-agreement'>
                { lang['footer_user_agreement'] }
              </Link>
              <Link className={styles.text} to='/page/privacy-policy'>
                { lang['footer_policy'] }
              </Link>
            </div>
          </>
        ) : (
          <></>
        )}
      </footer>
    ))
  );
};
