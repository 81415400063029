import {
    ACTION_SET_LANGUAGE_ID,
    ACTION_SET_LANG,
    ACTION_SET_LANG_SELECTED,
    ACTION_SET_ALERT_SUCCESS,
    ACTION_SET_ALERT_SUCCESS_TEXT,
    ACTION_SET_ALERT_DANGER,
    ACTION_SET_ALERT_DANGER_TEXT,
    ACTION_SET_LANGUAGES,
    ACTION_SET_PROJECTS,
    ACTION_SET_PROJECTS_MENU,
    ACTION_SET_BILLS,
    ACTION_SET_BILL_SEARCH,
    ACTION_SET_CURRENCIES,
    ACTION_SET_STAT,
    ACTION_SET_SHOW_MODAL_WITHDRAWAL,
    ACTION_SET_WITHDRAWAL_SYMBOL,
    ACTION_SET_SHOW_MODAL_INFO,
    ACTION_SET_MODAL_INFO_TITLE,
    ACTION_SET_MODAL_INFO_BODY,
} from '../actions/SiteAction'

let language_id = localStorage.getItem('language_id') ?? 1
let lang_selected = localStorage.getItem('lang_selected') ?? 'EN'


const initialState = {
    language_id: language_id,
    lang: {},
    lang_selected: lang_selected,
    show_modal_withdrawal: false, 
    show_modal_info: false, 
    modal_info_title: "", 
    modal_info_body: "", 
    withdrawal_symbol: '',
    alert_success: false,
    alert_success_text: '',
    alert_danger: false,
    alert_danger_text: '',
    languages: [],
    projects: [],
    projectsMenu: [],
    bills: [],
    bill_search: "",
    currencies: [],
    stat: {
        balance_usd: 0,
        bill_created_usd: 0,
        bill_success_usd: 0,
        bill_partial_usd: 0,
        bill_canceled_usd: 0,
        bill_created_amount: 0,
        bill_success_amount: 0,
        bill_partial_amount: 0,
        bill_canceled_amount: 0,
        wallet_currency: [],
        array_categories: [],
        array_bill_created_amount_hightchars: [],
        array_bill_success_amount_hightchars: [],
        array_bill_partial_amount_hightchars: []
    }
};


const siteReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_SET_LANGUAGE_ID: return { ...state, language_id: action.payload }
        case ACTION_SET_LANG: return { ...state, lang: action.payload }
        case ACTION_SET_LANG_SELECTED: return { ...state, lang_selected: action.payload }
        case ACTION_SET_ALERT_SUCCESS: return { ...state, alert_success: action.payload }
        case ACTION_SET_ALERT_SUCCESS_TEXT: return { ...state, alert_success_text: action.payload }
        case ACTION_SET_ALERT_DANGER: return { ...state, alert_danger: action.payload }
        case ACTION_SET_ALERT_DANGER_TEXT: return { ...state, alert_danger_text: action.payload }
        case ACTION_SET_LANGUAGES: return { ...state, languages: action.payload }
        case ACTION_SET_PROJECTS: return { ...state, projects: action.payload }
        case ACTION_SET_PROJECTS_MENU: return { ...state, projectsMenu: action.payload }
        case ACTION_SET_BILLS: return { ...state, bills: action.payload }
        case ACTION_SET_CURRENCIES: return { ...state, currencies: action.payload }
        case ACTION_SET_BILL_SEARCH: return { ...state, bill_search: action.payload }
        case ACTION_SET_STAT: return { ...state, stat: action.payload }
        case ACTION_SET_SHOW_MODAL_WITHDRAWAL: return { ...state, show_modal_withdrawal: action.payload }
        case ACTION_SET_SHOW_MODAL_INFO: return { ...state, show_modal_info: action.payload }
        case ACTION_SET_WITHDRAWAL_SYMBOL: return { ...state, withdrawal_symbol: action.payload }
        case ACTION_SET_MODAL_INFO_TITLE: return { ...state, modal_info_title: action.payload }
        case ACTION_SET_MODAL_INFO_BODY: return { ...state, modal_info_body: action.payload }
        default: return state
    }
}

export default siteReducer