import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Bills } from '../../components';
import { WidgetCreatePayment } from '../../components/WidgetCreatePayment';
import { WidgetStat } from '../../components/WidgetStat/WidgetStat';
import { WidgetStatPayments } from '../../components/WidgetStatPayments';
import { getCurrencies } from '../../http/siteAPI';
import { SIGNIN_ROUTER } from '../../utils/consts';

import styles from './MainPage.module.scss';

export const MainPage = () => {

  const lang = useSelector((state) => state.site.lang)
  const [currencies, setCurrencies] = useState([])
  const isAuth = useSelector((state) => state.user.isAuth)
  const stat = useSelector((state) => state.site.stat)
  const [loading, setLoading] = useState(true)

  const _load_async = async() => {
    let currencies_data = await getCurrencies()
    setCurrencies(currencies_data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  useEffect(() => {
    _load_async()
  },[])

  return (
    <div>
      { isAuth ? (

        <div className={styles['dashboard-container']}>
          <div className={styles['dashboard-title']}>{ lang['home_page_title'] }</div>
          <div className={styles['dashboard-flex-stat']}>
            <Link to={'/balance'}>
              <WidgetStat loading={loading} isLink={true} title={ lang['home_page_balance'] } icon="$" amount={ stat?.balance_usd }/>
            </Link>
            <WidgetStat loading={loading} title={ lang['home_page_invoice_created'] } icon="" amount={ stat?.bill_created_amount }/>
            <WidgetStat loading={loading} title={ lang['home_page_invoice_paid'] } icon="" amount={ stat?.bill_success_amount }/>
            <WidgetStat loading={loading} title={ lang['home_page_invoice_paid_partial'] } icon="" amount={ stat?.bill_partial_amount }/>
          </div>

          <div className={styles['dashboard-flex-create-bill']}>
            <WidgetCreatePayment loading={loading} />
            <WidgetStatPayments loading={loading} />
          </div>

          <Bills/>

        </div>

      ) : (

        <div className={styles['main-container']}>
          <div className={styles['main-left']}>
            <div className={styles['main-text-1']}>{ lang['main_page_top_text_1'] }</div>
            <div className={styles['main-text-2']}>{ lang['main_page_top_text_2'] }</div>
            <div className={styles['main-text-3']}>{ lang['main_page_top_text_3'] }</div>
            <div className={styles['main-text-4']}>{ lang['main_page_top_text_4'] }</div>

            <div className={styles['main-currencies-list']}>
              {currencies.length > 0 && currencies[0].currencies.map((item) => {
                if (item.image) {
                  return (
                    <div key={item.symbol} className={styles['main-text-symbol-container']}>
                      <div className={styles['main-text-symbol-left']}><img src={require("../../" + item.image)} alt="" className={styles['img-currency']}/></div>
                      <div className={styles['main-text-symbol-right']}>{ item.symbol }</div>
                    </div>
                  )
                }
              })}
            </div>

            <div className={styles['main-button-container']}>
              <Link to={SIGNIN_ROUTER}>
                <div className={styles['main-button']}>{ lang['main_button_text'] }</div>
              </Link>
            </div>

          </div>
          <div className={styles['main-right']}>
            <img src={require('../../ui-kit/assets/shutterstock_687394963-1.jpg')} alt="" className={styles['main-image']}/>
          </div>
        </div>

      )}
    </div>
  );
};
