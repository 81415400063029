import {
  MainPage,
  SignIn,
  SignUp,
  RestorePassword,
  RestoreEmailSent,
  Support,
  Information,
  News,
  NewsItem,
  ChangePassword,
  Page,
  RestorePasswordCreate,
  ConfirmEmail,
  ConfirmEmailData,
  Merchant,
  MerchantCreatePage,
  Setting,
  Security,
  Api,
  Fees,
  Contacts,
  Dashboard,
  CreateProject,
  Projects,
  Payments,
  Pay,
  AdminUserBalance,
  AdminLocalkassaBalance,
  PayPayeer,
} from '../../pages';
import { Balance } from '../../pages/Balance';


import {
  CHANGE_PASSWORD_ROUTER,
  HISTORY_ROUTER,
  MAIN_ROUTER,
  NEWS_ONE_ROUTER,
  NEWS_ROUTER,
  SIGNIN_ROUTER, 
  SIGNUP_ROUTER, 
  SUPPORT_ROUTER, 
  PAGE_ROUTER,
  SUPPORT_INFORMATION_ROUTER,
  PASSWORD_RESTORE_ROUTER,
  PASSWORD_RESTORE_CREATE_ROUTER,
  RESTORE_EMAIL_SENT_ROUTER,
  CONFIRM_EMAIL_ROUTER,
  MERCHANT_ROUTER,
  MERCHANT_CREATE_ROUTER,
  API_ROUTER,
  DASHBOARD_ROUTER,
  PARTNER_ROUTER,
  SETTING_ROUTER,
  SECURITY_ROUTER,
  FEES_ROUTER,
  CONTACT_ROUTER,
  CREATE_PROJECT_ROUTER,
  PROJECTS_ROUTER,
  PROJECT_ROUTER,
  PAYMENTS_ROUTER,
  BALANCE_ROUTER,
  PAY_ROUTER,

  PAY_SUCCESS_ROUTER,
  PAY_FAIL_ROUTER,
  
  ADMIN_USER_BALANCE_ROUTER,
  ADMIN_LOCALKASSA_BALANCE_ROUTER,
} from '../../utils/consts'


export const adminRoutes = [
  {
    path: ADMIN_USER_BALANCE_ROUTER,
    name: 'admin-user-balance',
    element: <AdminUserBalance />,
  },
  {
    path: ADMIN_LOCALKASSA_BALANCE_ROUTER,
    name: 'admin-localkassa-balance',
    element: <AdminLocalkassaBalance />,
  },
]

export const pageRoutes = [
  {
    path: SIGNIN_ROUTER,
    name: 'sign-in',
    element: <SignIn />,
  },
  {
    path: PASSWORD_RESTORE_ROUTER,
    name: 'password-restore',
    element: <RestorePassword />,
  },
  {
    path: PASSWORD_RESTORE_CREATE_ROUTER,
    name: 'password-restore-create',
    element: <RestorePasswordCreate />,
  },
  {
    path: SIGNUP_ROUTER,
    name: 'sign-up',
    element: <SignUp />,
  },
  {
    path: RESTORE_EMAIL_SENT_ROUTER,
    name: 'restore-email-sent',
    element: <RestoreEmailSent />,
  },
  {
    path: NEWS_ROUTER,
    name: 'news',
    element: <News />,
  },
  {
    path: NEWS_ONE_ROUTER,
    name: 'news_item',
    element: <NewsItem />,
  },

  {
    path: PAGE_ROUTER,
    name: 'page',
    element: <Page />,
  },
  {
    path: API_ROUTER,
    name: 'api',
    element: <Api />,
  },
  {
    path: FEES_ROUTER,
    name: 'fees',
    element: <Fees />
  },
  {
    path: CONTACT_ROUTER,
    name: 'сontacts',
    element: <Contacts />
  }
]

export const authRoutes = [
 {
    path: MERCHANT_ROUTER,
    name: 'merchant',
    element: <Merchant />,
  },
  { 
    path: MERCHANT_CREATE_ROUTER,
    name: 'merchant-create',
    element: <MerchantCreatePage />,
  },
  {
    path: SUPPORT_ROUTER,
    name: 'support',
    element: <Support />,
  },
  {
    path: SUPPORT_INFORMATION_ROUTER,
    name: 'information',
    element: <Information />,
  },
  {
    path: CHANGE_PASSWORD_ROUTER,
    name: 'change-password',
    element: <ChangePassword />,
  },
  {
    path: CONFIRM_EMAIL_ROUTER,
    name: 'confirm-email',
    element: <ConfirmEmailData />,
  },
  {
    path: CREATE_PROJECT_ROUTER,
    name: 'create-project',
    element: <CreateProject />,
  },
  {
    path: PROJECTS_ROUTER,
    name: 'projects',
    element: <Projects />,
  },
  {
    path: PROJECT_ROUTER,
    name: 'project',
    element: <CreateProject />,
  },
  {
    path: PAYMENTS_ROUTER,
    name: 'payments',
    element: <Payments />,
  },
  {
    path: BALANCE_ROUTER,
    name: 'balance',
    element: <Balance />,
  },
  {
    path: SETTING_ROUTER,
    name: 'setting-router',
    element: <Setting />,
  },
]


export const payRoutes = [
  {
    path: PAY_ROUTER,
    name: 'pay',
    element: <Pay />,
  },
  {
    path: PAY_SUCCESS_ROUTER,
    name: 'pay_success',
    element: <PayPayeer />,
  },
  {
    path: PAY_FAIL_ROUTER,
    name: 'pay_fail',
    element: <PayPayeer />,
  },
]

export const publicRoutes = [
  {
    path: MAIN_ROUTER,
    name: 'main-page',
    element: <MainPage />,
  }
];
