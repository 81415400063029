import { useSelector } from 'react-redux';
import { Bills } from '../../components';
import { WidgetCreatePayment } from '../../components/WidgetCreatePayment';
import { WidgetStat } from '../../components/WidgetStat/WidgetStat';
import { WidgetStatPayments } from '../../components/WidgetStatPayments';
import styles from './Payments.module.scss';

export const Payments = () => {

  const stat = useSelector((state) => state.site.stat)
  const lang = useSelector((state) => state.site.lang)

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>{ lang['widget_payments_title'] }</div>

      <div className={styles['dashboard-flex-create-bill']}>
        <WidgetCreatePayment />
        <WidgetStatPayments />
      </div>

      <div className={styles['dashboard-flex-stat']}>
        <WidgetStat title={ lang['widget_payments_stat_1'] } icon="$" amount={ stat?.bill_created_usd } amountUnder={ stat?.bill_created_amount } color="rgb(74,64,198)"/>
        <WidgetStat title={ lang['widget_payments_stat_2'] } icon="$" amount={ stat?.bill_success_usd } amountUnder={ stat?.bill_success_amount } color="rgb(88,189,125)"/>
        <WidgetStat title={ lang['widget_payments_stat_3'] } icon="$" amount={ stat?.bill_partial_usd } amountUnder={ stat?.bill_partial_amount } color="rgb(251,193,81)"/>
        <WidgetStat title={ lang['widget_payments_stat_4'] } icon="$" amount={ stat?.bill_canceled_usd } amountUnder={ stat?.bill_canceled_amount } color="rgb(249,74,135)"/>
      </div>

      <Bills/>

    </div>
  )
}