import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrencies, getProject, getProjectCurrency, getProjects, saveProject } from '../../http/siteAPI';
import { actionSetProjects, actionSetProjectsMenu } from '../../store/actionCreators/siteActionCreator';
import { Input, InputText } from '../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../utils/function';

import styles from './CreateProject.module.scss';

export const CreateProject = () => {

  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const lang = useSelector((state) => state.site.lang)
  const language_id = useSelector((state) => state.site.language_id)
  
  const [title, setTitle] = useState('')
  const [second_title, setSecondTitle] = useState('')
  const [btn_text, setBtnText] = useState(lang['create_project_save'])

  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [description, setDescription] = useState('')
  const [site, setSite] = useState('')
  const [success_url, setSuccessUrl] = useState('')
  const [failure_url, setFailureUrl] = useState('')
  const [postback_url, setPostbackUrl] = useState('')
  const [currStatus, setCurrStatus] = useState([])
  const [api_key, setApiKey] = useState('')
  const [shop_id, setShopId] = useState('')
  const [secret_key, setSecretKey] = useState('')

  const [nameError, setNameError] = useState('')
  const [codeError, setCodeError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  
  const [type_payments, setCurrencies] = useState([])

  const handleChange = (e, symbol) => {
    setCurrStatus({...currStatus, [symbol]: e.target.checked ? 1 : 0})
  }

  const _load_async = async() => {
    
    let currencies_data = await getCurrencies()
    setCurrencies(currencies_data)

    let temp = {}
    if (id) {
      let project_currency = await getProjectCurrency(id)
      currencies_data.map((i) => {
        i.currencies.map((cc) => {
          temp[cc.symbol] = project_currency[cc.symbol] ?? 0
        })
      })
    } else {
      currencies_data.map((i) => {
        i.currencies.map((cc) => {
          temp[cc.symbol] = 1
        })
      })      
    }
    
    setCurrStatus(temp)
    
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  useEffect(() => {
    _load_async()
  },[id])

  const _load_async_project = async () => {
    if (id) {
      let p = await getProject(id)
      setName(p.name)
      setCode(p.code)
      setDescription(p.description)
      setSite(p.site)
      setSuccessUrl(p.success_url)
      setFailureUrl(p.failure_url)
      setPostbackUrl(p.postback_url)
      setTitle(p.name)
      setApiKey(p.api_key)
      setShopId(p.shop_id)
      setSecretKey(p.secret_key)
      setSecondTitle(lang['create_project_edit_project'])
    } else {
      setName('')
      setCode('')
      setDescription('')
      setSite('')
      setSuccessUrl('')
      setFailureUrl('')
      setPostbackUrl('')
      setApiKey('')
      setShopId('')
      setSecretKey('')
      setTitle(lang['create_project_add_project'])
      setSecondTitle(lang['create_project_add_project_process'])
    }
  }

  useEffect(() => { 
    _load_async_project()
  },[id])



  const createProject = async () => {

    setNameError('')
    setCodeError('')
    setDescriptionError('')

    if (name.length < 3) {
      setNameError(lang['create_project_title_error'])
    }
    
    if (code.length < 3) {
      setCodeError(lang['create_project_type_activity_label'])
    }
    
    if (description.length < 3) {
      setDescriptionError(lang['create_project_desc_error'])
    }

    if (nameError == '' && codeError == '' && descriptionError == '') {

      try {

        setBtnText(lang['create_project_saving'])

        const projectNew = await saveProject({
          id, name, code, description, site, success_url, failure_url, postback_url, currStatus, language_id
        })

        alertSuccess(dispatch, lang['create_project_project_saved_succesfully'])

        navigate('/project/' + projectNew.project_id)

        let temp = await getProjects()
        let tempMenu = []
        temp.map((i) => {
          tempMenu.push({
            name: i.name,
            url: '/project/' + i.id,
            active: ['/project/' + i.id]
          })
        })
        dispatch(actionSetProjects(temp))
        dispatch(actionSetProjectsMenu(tempMenu))
        
        setTimeout(() => {
          setBtnText(lang['create_project_save'])
        }, 1000)

      } catch(e) {
        alertDanger(dispatch,e.response.data.message)
        setBtnText(lang['create_project_save'])
      }

    }
    
  }

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>{ title }</div>
      
      <div className={styles['block']}>

        <div className={styles['block-title-container']}>
          <div className={styles['block-title']}>{ second_title }</div>
        </div>

        <div className={styles['grid-step-container']}>

          <div>

            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_name'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={name} setInputValue={setName} label='' />
              </div>
              <div className={styles['error']}>{ nameError }</div>
            </div>

            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_type_activity'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={code} setInputValue={setCode} label='' />
              </div>
              <div className={styles['error']}>{ codeError }</div>
            </div>

            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_project_desc'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={description} setInputValue={setDescription} label='' />
              </div>
              <div className={styles['error']}>{ descriptionError }</div>
            </div>



            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_site'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={site} setInputValue={setSite} label='' />
              </div>
            </div>


            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_success_url'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={success_url} setInputValue={setSuccessUrl} label='' />
              </div>
            </div>


            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_faild_url'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={failure_url} setInputValue={setFailureUrl} label='' />
              </div>
            </div>


            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_url'] }</div>
              <div className={styles['item-input-field']}>
                <InputText className={styles.input} defvalue={postback_url} setInputValue={setPostbackUrl} label='' />
              </div>
            </div>

            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}></div>
              <div className={styles['item-input-field']}>
                <div className={styles['button']} onClick={createProject}>{ btn_text }</div>
              </div>
            </div>


          </div>

          <div>
            
            <div className={styles['item-input']}>
              <div className={styles['item-input-title']}>{ lang['create_project_choose_valute'] }</div>
              <div className={styles['item-input-field']}>
                <table className={styles['table']}>
                  {type_payments.map((item) => {

                    return (

                      <div key={`type_payment_${item.type_payment_id}`}>

                        <div className={styles['item-type_payment-title']}><strong>{ item.type_payment.name }</strong></div>
                    
                        { item.currencies.map((item_cur) => {

                          currStatus[item_cur.symbol] = currStatus[item_cur.symbol] ?? 0
                          let checked = currStatus[item_cur.symbol] == 1 ? true : false

                          return (
                            <tr key={item_cur.symbol} className={styles['main-text-symbol-container']}>
                              <td><Switch onChange={(e) => handleChange(e, item_cur.symbol)} {...label} checked={checked} /></td>
                              <td><img src={require("../../" + item_cur.image)} alt="" className={styles['img-currency']}/></td>
                              <td>{ item_cur.symbol }</td>
                            </tr>
                          )

                        }) }

                      </div>

                    )

                  })}
                </table>
                
              </div>

              { api_key !== '' && (
                <div className={styles['api-block']}>
    
                  <div className={styles['item-input-title-api']}>{ lang['project_shop_id'] }</div>
                  <div className={styles['item-input-title-api-text']}>{ shop_id }</div>

                </div>
              )}

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}