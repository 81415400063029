import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrencies } from '../../http/siteAPI';

import styles from './Fees.module.scss';

export const Fees = () => {

  const lang = useSelector((state) => state.site.lang)
  const [currencies, setCurrencies] = useState([])

  const _load_async = async() => {
    let currencies_data = await getCurrencies()
    setCurrencies(currencies_data)
  }

  useEffect(() => {
    _load_async()
  },[])

  return (
    <div className={styles['main-content']}>
        
      <div className={styles['title-container']}>
        <div className={styles['title']}>{ lang?.fees_title }</div>
      </div>

      <div>
        <table className={styles['table']}>

          <tr>
            <td><div className={styles['main-text-symbol-image']}></div></td>
            <td><div className={styles['main-text-symbol-min_amount']}>{ lang?.fees_crypto }</div></td>
            <td><div className={styles['main-text-symbol-symbol']}>{ lang?.fees_crypto_min }</div></td>
            <td><div className={styles['main-text-symbol-comissions']}>{ lang?.fees_comission }</div></td>
          </tr>
          
          { currencies.length > 0 && currencies[0].currencies.map((item) => {
            return (
              <tr>
                <td><div className={styles['main-text-symbol-image']}><img src={require("../../" + item.image)} alt="" className={styles['img-currency']}/></div></td>
                <td><div className={styles['main-text-symbol-min_amount']}>{ item.name }</div></td>
                <td><div className={styles['main-text-symbol-symbol']}>{ item.min_amount } { item.symbol }</div></td>
                <td><div className={styles['main-text-symbol-comissions']}>{ item.comissions }% + network fee*</div></td>
              </tr>
            )
          })}
        </table>
      </div>

    </div>
  )
}