import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { getUserBalances } from '../../http/adminApi';
import styles from './AdminUserBalance.module.scss';

export const AdminUserBalance = () => {
  const [balances, setBalances] = useState([])
  const [bill_currency_addresses, setBillCurrencyAddresses] = useState([])

  const __load_async = async () => {
    let temp = await getUserBalances()
    setBalances(temp.balances)
    setBillCurrencyAddresses(temp.bill_currency_addresses)
  }

  useEffect(() => {
    __load_async()
  },[])

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>Баланс всех пользователей</div>
      <div style={{marginTop: 20, maxWidth: 300}}>
        <table className={styles['table-admin']}>
          <tbody>
            { balances.map((b) => {
              return (
                <tr>
                  <td>{b.email}</td>
                  <td>{b.symbol}</td>
                  <td>{b.balance} {b.symbol}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <br/>
      <br/>
      <div className={styles['dashboard-title']}>bill_currency_addresses</div>
      <div style={{marginTop: 20, maxWidth: 500}}>
        <table className={styles['table-admin']}>
          <thead>
            <tr>
              <td>ID</td>
              <td>BILL_ID</td>
              <td>SYMBOL</td>
              <td>BALANCE</td>
            </tr>
          </thead>
          <tbody>
            { bill_currency_addresses.map((b) => {
              return (
                <tr>
                  <td>{b.id}</td>
                  <td>{b.bill_id}</td>
                  <td>{b.symbol}</td>
                  <td>{b.balance} {b.symbol}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}