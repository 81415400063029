import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Nav, LoginButton, LanguageMenu, BurgerMenu, SideBar, AlertComponent } from '..';
import { useWindowSize } from '../../hooks';
import { Close, Logo } from '../../ui-kit/assets';
import AlertDanger from '../Alert/AlertDanger/AlertDanger';
import AlertSuccess from '../Alert/AlertSuccess/AlertSuccess';


import styles from './Header.module.scss';

const pagesToShowCloseButton = {
  '/sign-in': true,
  '/sign-up': true,
  '/password-restore': true,
  '/restore-email-sent': true,
  '/balance/deposit-refill': true,
};

export const Header = ({ logged }) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const location = useLocation();
  let pathname = location.pathname.includes('/balance/deposit-refill') ? '/balance/deposit-refill' : location.pathname
  const isOnLoginStage = pagesToShowCloseButton[pathname];

  const isAuth = useSelector((state) => state.user.isAuth)
  const user = useSelector((state) => state.user.user)
  const alert_success = useSelector((state) => state.site.alert_success)
  const alert_success_text = useSelector((state) => state.site.alert_success_text)
  const alert_danger = useSelector((state) => state.site.alert_danger)
  const alert_danger_text = useSelector((state) => state.site.alert_danger_text)
  
  const onCloseClick = () => {
    navigate('/');
  };

  return (
    <>
      { !logged && (
        <header className={styles.header}>
          <div className={styles.logo}>
            <Link to={'/'}>
              <div className={styles['logo-localkassa']}><img className={styles['logo-localkassa-img']} src={Logo}/></div>
            </Link>
          </div>
          {!isOnLoginStage ? (
            !isMobile ? (
              <>
                <Nav logged={logged}/>
                <div className={styles.menus}>
                  <LoginButton logged={logged} />
                  <LanguageMenu />
                </div>
              </>
            ) : (
              <>
                <div className={styles.menus}>
                  <BurgerMenu logged={logged} />
                  &nbsp;
                  &nbsp;
                  <LanguageMenu />
                </div>
              </>
            )
          ) : (
            <div className={styles.close} onClick={onCloseClick}>
              <Close />
            </div>
          )}
        </header>
      )}

      { logged && (
        <SideBar />
      )}

      <AlertComponent />

    </>
  );
};
