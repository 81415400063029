import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { 
  publicRoutes,
  authRoutes,
  payRoutes,
  pageRoutes,
  adminRoutes,
} from './router';
import 'bootstrap/dist/css/bootstrap.min.css';


import { 
  actionSetIsAdmin,
  actionSetIsAuth,
  actionSetUserData
} from '../store/actionCreators/userActionCreator'

import { useWindowSize } from '../hooks';
import { Header, Footer, MobileNav, SideBar } from '../components';

import '../index.scss';
import styles from './App.module.scss';
import { useEffect, useState } from 'react'
import { checkIsAuth } from '../http/userAPI'
import { useSelector, useDispatch } from 'react-redux'
import { MainPage } from '../pages';
import { actionSetLang, actionSetLanguages } from '../store/actionCreators/siteActionCreator';
import { getLanguages, getTranslation } from '../http/siteAPI';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const App = () => {

  const dispatch = useDispatch()
  const isAuth = useSelector(store => store.user.isAuth)
  const isAdmin = useSelector(store => store.user.isAdmin)
  const lang_selected = useSelector(store => store.site.lang_selected)
  const [loading, setLoading] = useState(true)


  const load_lang = async() => {

    let lang = await getTranslation(lang_selected)
    dispatch(actionSetLang(lang))

    let languages = await getLanguages()
    dispatch(actionSetLanguages(languages))

  }

  const __load_async = async() => {

    await load_lang()

    try {

      let data = await checkIsAuth()

      dispatch(actionSetIsAuth(true))
      dispatch(actionSetUserData(data)) 

      console.log(data)

      if (data.is_admin == 1) {
        dispatch(actionSetIsAdmin(true))
      }

    } catch (e) {

      
    }

    setLoading(false)

  }

  useEffect(() => {
    __load_async()
  },[lang_selected])




  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const PayLayout = () => {
    return (
      <div>
        <Outlet />
      </div>
    )
  }

  const AppLayout = () => (
    <>
      <div className={isAuth ? styles['dashboard'] : styles['app']}>
        <Header logged={isAuth} />
        <main className={isAuth ? styles['main-contant'] : styles['main']}>
          <Outlet />
        </main>
        { !isAuth && <Footer /> }
      </div>
    </>
  )

  const PageLayout = () => (
    <>
      <div className={styles['app']}>
        <Header logged={false} />
        <main className={styles['main']}>
          <Outlet />
        </main>
        { <Footer /> }
      </div>
    </>
  )

  return (

      <BrowserRouter>

        <Routes>

          <Route element={<PayLayout />} >
            {payRoutes.map(elem => (
              <Route {...elem} key={elem.name} />
            ))}
          </Route>

          <Route element={<PageLayout />} >
            {pageRoutes.map(elem => (
              <Route {...elem} key={elem.name} />
            ))}
          </Route>

          <Route element={<AppLayout />} >
            {publicRoutes.map(elem => (
              <Route {...elem} key={elem.name} />
            ))}
            {isAuth && authRoutes.map(elem => (
              <Route {...elem} key={elem.name} />
            ))}
            {isAdmin && adminRoutes.map(elem => (
              <Route {...elem} key={elem.name} />
            ))}
          </Route>

          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />

        </Routes>

      </BrowserRouter>

  );
};
