import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBills, getCurrencies, getFullStat, getProjectCurrencies, getProjectCurrency, saveBill } from '../../http/siteAPI';
import { actionSetBills, actionSetStat } from '../../store/actionCreators/siteActionCreator';
import { InputText } from '../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../utils/function';
import CopyText from '../CopyText/CopyText';

import styles from './WidgetCreatePayment.module.scss';

export const WidgetCreatePayment = () => {

  const dispatch = useDispatch()
  const lang = useSelector((state) => state.site.lang)
  const language_id = useSelector((state) => state.site.language_id)
  const bill_search = useSelector((state) => state.site.bill_search)
  
  const [btn_text, setBtnText] = useState(lang['widget_create_payment_create_bill'])
  const [projectCurrencies, setProjectCurrencies] = useState([])
  const [symbol, setSymbol] = useState('')
  const [project_id, setProjectId] = useState("0")
  const [amount, setAmount] = useState(0)
  const [show, setShow] = useState(false)
  const [cpu, setCpu] = useState('')
  const [errorProjectId, setErrorProjectId] = useState('')
  const [errorAmount, setErrorAmount] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const projects = useSelector((state) => state.site.projects)



  const createBill = async () => {

    setErrorAmount('')
    setErrorProjectId('')
    
    try {

      setBtnText(lang['process_saving'])

      let amount_check = Number(amount)
      if (isNaN(amount_check) || amount_check <= 0) {
        setErrorAmount(lang['widget_create_payment_enter_amount'])
      }

      if (project_id == "0") {
        setErrorProjectId(lang['widget_create_payment_need_choose_project'])
      }

      if (errorProjectId == "" && errorAmount == "") {
          
        const bill = await saveBill({
          amount, project_id, symbol, language_id
        })

        alertSuccess(dispatch, lang['widget_create_payment_bill_successfully_created'])

        setCpu(bill.cpu)

        handleShow()

        let temp_bills = await getBills(bill_search)
        dispatch(actionSetBills(temp_bills))

        let temp_stat = await getFullStat()
        dispatch(actionSetStat(temp_stat))
    

      }

    } catch(e) {

      alertDanger(dispatch,e.response.data.message)

    }

    setTimeout(() => {
      setBtnText(lang['widget_create_payment_save'])
    }, 1000)

  }
  
  const _load_async = async() => {

    if (project_id != "0") {
      let project_currencies_data = await getProjectCurrencies(project_id)
      setProjectCurrencies(project_currencies_data)
      if (project_currencies_data.length > 0) {
        setSymbol(project_currencies_data[0].symbol)
      }
    }
    
  }


  
  

  useEffect(() => {
    _load_async()
  },[project_id])

  return (
    <div className={styles['container']}>

      <div className={styles['block-title']}>{ lang['widget_create_payment_create_bill'] }</div>
      
      <div className={styles['item-input']}>
        <div className={styles['item-input-title']}>{ lang['widget_create_payment_bill_amount'] }</div>
        <div className={styles['item-input-field']}>
          <InputText type="number" step="any" defValue={amount} setInputValue={setAmount} className={styles.input}  />
        </div>
        { errorAmount !== "" && <div className={styles['error']}>{ errorAmount }</div>}
      </div>


      
      <div className={styles['item-input']}>
        <div className={styles['item-input-title']}>{ lang['widget_create_payment_project'] }</div>
        <div className={styles['item-input-field']}>
          <select className={styles.select} value={project_id} onChange={(e) => setProjectId(e.target.value)}>
            <option disabled value="0">{ lang['widget_create_payment_choose_project'] }</option>
            { projects.map((p) => {
              return (
                <option value={ p.id }>{ p.name }</option>
              )
            })}
          </select>
        </div>
        { errorProjectId !== "" && <div className={styles['error']}>{ errorProjectId }</div>}
      </div>
       
      <div className={styles['item-input']}>
        <div className={styles['item-input-title']}>{ lang['widget_create_payment_valute'] }</div>
        <div className={styles['item-input-field']}>
          <select className={styles.select} value={symbol} onChange={(e) => setSymbol(e.target.value)}>
            { projectCurrencies.map((c) => {
              return (
                <option value={ c.symbol }>{ c.name } ({ c.symbol })</option>
              )
            })}
          </select>
        </div>
      </div>
      
      
      <div className={styles['item-input']}>
        <div className={styles['item-input-title']}></div>
        <div className={styles['item-input-field']}>
          <div className={styles['button']} onClick={createBill}>{ btn_text }</div>
        </div>
      </div>

      <Modal show={show} className={styles['modal']} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title><span className="fa fa-money" style={{color: "rgb(255,125,50)"}}></span> &nbsp; { lang['widget_create_payment_bill_created'] }</Modal.Title>
          <div onClick={handleClose}><span class="fa fa-times"></span></div>
        </Modal.Header>
        <Modal.Body>

          <div className={styles['modal-undertitle']}>{ lang['widget_create_payment_text_1'] } { amount } { symbol } { lang['widget_create_payment_text_2'] }</div>
          
          <div className={styles['modal-table-container']}>
            <table className={styles['modal-table']}>
              <tr><td>{ lang['widget_create_payment_bill_id'] }</td><td>{ cpu }</td></tr>
              <tr><td>{ lang['widget_create_payment_amount'] }</td><td>{ amount } { symbol }</td></tr>
            </table>
          </div>

          <div style={{marginTop: 30, marginBottom: 15, fontWeight: 600}}>{ lang['widget_create_payment_link'] }</div> 

          <div className={styles['footer-modal-bill']}>
            <div><a target="_blank" href={ "https://localkassa.com/pay/" + cpu }>{ "https://localkassa.com/pay/" + cpu }</a></div>
            <div><CopyText text={"https://localkassa.com/pay/" + cpu}/></div>
          </div>
                    
        </Modal.Body>
      </Modal>

    </div>
  )
}