import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './InputText.module.scss';
import uid from 'uniqid'

export const InputText = ({ label, control, type, step, dynamicLabel, className, inputClassName, setInputValue, defvalue, disabled, autocomplete, onBlur, datalist }) => {

  defvalue = defvalue ?? ''
  disabled = disabled ?? false
  step = step ?? 'any'
  autocomplete = autocomplete ?? 'on'
  datalist = datalist ?? []

  const [value, setValue] = useState(defvalue)

  const updateValue = (val) => {
    setValue(val)
    setInputValue(val)
  }

  useEffect(() => {
    setValue(defvalue)
  },[defvalue])

  const uniqid = uid()

  return (
    <div className={classNames(styles.body, className)}>
      <input
        list={ datalist.length > 0 ? uniqid : '' }
        autocomplete={autocomplete}
        value={value}
        disabled={disabled}
        onChange={(e)=>updateValue(e.target.value)}
        onBlur={onBlur}
        className={classNames(styles.input, inputClassName, {
          [styles['input--dynamic-label']]: dynamicLabel,
        })}
        {...control}
        placeholder={!dynamicLabel ? label : ' '}
        type={type}
      />
      {dynamicLabel && <span className={classNames(styles.label)}>{label}</span>}
      { datalist.length > 0 && (
        <datalist id={uniqid}>
          {datalist.map((i) => {
            return (
              <option>{ i.name }</option>
            )
          })}
        </datalist>  
      )}
      
    </div>
  );
};
