import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionButton } from '../../ui-kit/components';
import { MERCHANT_CREATE_ROUTER } from '../../utils/consts';
import { getApiData, setApiData } from '../../http/userAPI';
import { alertDanger, alertSuccess } from '../../utils/function';

import styles from './Setting.module.scss';

export const Setting = () => {

  const dispatch = useDispatch()
  const lang = useSelector((state) => state.site.lang)
  const language_id = useSelector((state) => state.site.language_id)

  const [api_key, set_api_key] = useState(null)
  const [api_key_hidden, set_api_key_hidden] = useState(null)
  const [secret_key, set_secret_key] = useState(null)
  const [secret_key_hidden, set_secret_key_hidden] = useState(null)
  const [api_key_created, set_api_key_created] = useState(null)
  const [api_key_created_datetime, set_api_key_created_datetime] = useState(null)
  const [btn_text, setBtnText] = useState('Сгенерировать новый ключ')

  const createApi = async () => {

    try {

      setBtnText("Подождите...")

      let data = await setApiData(language_id)
      set_api_key(data?.api_key ?? null)
      set_api_key_hidden(data?.api_key_hidden ?? null)
      set_secret_key(data?.secret_key ?? null)
      set_secret_key_hidden(data?.secret_key_hidden ?? null)
      set_api_key_created(data?.api_key_created ?? null)
      set_api_key_created_datetime(data?.api_key_created_datetime ?? null)

      alertSuccess(dispatch, "Ключ успешно сгенерирован")

      setTimeout(() => {
        setBtnText("Ключ сгенерирован")
      }, 1000)

      setTimeout(() => {
        setBtnText("Сгенерировать новый ключ")
      }, 2000)
      

    } catch(e) {

      alertDanger(dispatch,e.response.data.message)
      setBtnText("Сгенерировать новый ключ")

    }

  }
  const _load_async = async() => {

    try {

      let data = await getApiData(language_id);
      set_api_key(data?.api_key ?? null)
      set_api_key_hidden(data?.api_key_hidden ?? null)
      set_api_key_created(data?.api_key_created ?? null)
      set_api_key_created_datetime(data?.api_key_created_datetime ?? null)
      set_secret_key(data?.secret_key ?? null)
      set_secret_key_hidden(data?.secret_key_hidden ?? null)

    } catch(e) {

      alertDanger(dispatch,e.response.data.message)

    }

  }

  useEffect(() => {
    _load_async()
  },[])

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>Настройки</div>
      <div className={styles['setting-list']}>
        
        <div className={styles['title']}>Настройки API</div>
        <div>
          { api_key_created == 1 ? (
            <div>
              <div className={styles['title-not-isset']}>API ключ: <strong>{ api_key == null ? api_key_hidden : api_key }</strong> </div>
              <div className={styles['title-not-isset']}>Секретный ключ: <strong>{ secret_key == null ? secret_key_hidden : secret_key }</strong> </div>
              <div className={styles['title-not-isset']}>{ api_key != null ? 'Скопируйте ключ, и храните в надежном месте. После обновления страницы ключ станет невидимым.' : '' }</div>
              <div className={styles['button']} onClick={createApi}>{ btn_text }</div>
            </div>
          ) : (
            <div>
              <div className={styles['title-not-isset']}>API ключ ещё не создан</div>
              <div className={styles['button']} onClick={createApi}>{ btn_text }</div>
            </div>
          ) }
        </div>
        
      </div>
    </div>
  );
};
