import { Skeleton } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetShowModalWithdrawal, actionSetWithdrawalSymbol } from '../../store/actionCreators/siteActionCreator'
import styles from './WidgetBalance.module.scss'

export const WidgetBalance = () => {

  const dispatch = useDispatch()
  const stat = useSelector((state) => state.site.stat)
  const lang = useSelector((state) => state.site.lang)
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 1000)
  console.log(stat.wallet_currency)

  let arr = []
  for (let i = 0; i < 8; i++) {
    arr.push(i)
  }

  const chooseCurrency = (symbol = '') => {
    if (symbol !== '') dispatch(actionSetWithdrawalSymbol(symbol))
    dispatch(actionSetShowModalWithdrawal(true))
  }

  if (loading) {
    
    return (
      <div className={styles['grid-container']}>
        { arr.map((i) => {
          return (
            <div className={styles['gird-item']} key={"temp_" + i}>
              <div className={styles['grid-height']}>
                <div>
                  <div className={styles['amount-container']}>
                    <div><Skeleton variant="circular" width={30} height={30} /></div>
                    <div><Skeleton variant="rectangular" width={60} height={20} /></div>
                  </div>
                  <div className={styles['amount']}><Skeleton variant="rectangular" width={"60%"} height={20} /></div>
                </div>
                <div style={{marginTop: 60}}>
                  <Skeleton variant="rectangular" height={30} />
                </div>
              </div>
            </div>
          )
        })}
        

      </div>
    )

  }

  return (
    <div className={styles['grid-container']}>
      <div className={styles['gird-item-balance']}>
        <div className={styles['title-balance']}>{ lang['widget_balance_title'] }</div>
        <div className={styles['balance']}>$ { stat.balance_usd }</div>
        <div onClick={() => chooseCurrency()} className={styles['btn-withdrawal']} style={{marginTop: 43}}>{ lang['widget_balance_withdrawal'] }</div>
      </div>
      
      {stat.wallet_currency.map((wallet) => {
        return (
          <div className={styles['gird-item']} key={wallet.currency.currency_id}>
            <div className={styles['grid-height']}>
              <div>
                <div className={styles['amount-container']}>
                  <div><img src={require("../../" + wallet.currency.image)} alt="" className={styles['img-currency']}/></div>
                  <div>{ wallet.currency.name }</div>
                </div>
                <div className={styles['amount']}>{ wallet.amount_pretty } { wallet.currency.symbol }</div>
              </div>
              <div>
                <div onClick={() => chooseCurrency(wallet.currency.symbol)} className={styles['btn-withdrawal-crypto']}>{ lang['widget_balance_withdrawal'] }</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}