export const SIGNIN_ROUTER = '/sign-in'
export const SIGNUP_ROUTER = '/sign-up'
export const MAIN_ROUTER = '/'
export const NEWS_ROUTER = '/news'
export const NEWS_ONE_ROUTER = '/news/:id'
export const HISTORY_ROUTER = '/history'
export const SUPPORT_ROUTER = '/support'
export const CHANGE_PASSWORD_ROUTER = '/change-password'
export const PAGE_ROUTER = '/page/:slug'
export const SUPPORT_INFORMATION_ROUTER = '/support/information/:id'
export const PASSWORD_RESTORE_ROUTER = '/password-restore'
export const PASSWORD_RESTORE_CREATE_ROUTER = '/restore-password-create/:uid'
export const RESTORE_EMAIL_SENT_ROUTER = '/restore-email-sent'
export const CONFIRM_EMAIL_ROUTER = '/confirm-email/:uid'
export const MERCHANT_ROUTER = '/merchants'
export const MERCHANT_CREATE_ROUTER = '/merchant-create'
export const API_ROUTER = '/apis'
export const PARTNER_ROUTER = '/partners'
export const SETTING_ROUTER = '/settings'
export const SECURITY_ROUTER = '/security'
export const FEES_ROUTER = '/fees'
export const CONTACT_ROUTER = '/contacts'
export const DASHBOARD_ROUTER = '/dashboard'
export const CREATE_PROJECT_ROUTER = '/create-project'
export const PROJECTS_ROUTER = '/projects'
export const PAYMENTS_ROUTER = '/payments'
export const BALANCE_ROUTER = '/balance'
export const PROJECT_ROUTER = '/project/:id'
export const PAY_ROUTER = '/pay/:cpu'
export const PAY_SUCCESS_ROUTER = '/pay/success'
export const PAY_FAIL_ROUTER = '/pay/fail'
export const ADMIN_LOCALKASSA_BALANCE_ROUTER = '/admin/balance/user'
export const ADMIN_USER_BALANCE_ROUTER = '/admin/balance/localkassa'