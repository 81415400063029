import { useSelector } from 'react-redux';
import styles from './Contact.module.scss';
import { useState } from 'react';
import { getCurrencies } from '../../http/siteAPI';
import { useEffect } from 'react';
export const Contacts = () => {

  
  const lang = useSelector((state) => state.site.lang)
  const [currencies, setCurrencies] = useState([])
  const _load_async = async() => {
    let currencies_data = await getCurrencies()
    setCurrencies(currencies_data)
  }

  useEffect(() => {
    _load_async()
  },[])


  return (
    <>
      <div>
        <div className={styles['main-container']}>
          <div className={styles['main-left']}>
            <div className={styles['main-text-1']}>{ lang?.about_title }</div>
            <div className={styles['main-text-4']}>
              <div className="page-text">

                <div className={styles['flex-container']}>
                  <div><img src="/img/about-us.png"/></div>
                  <div>
                    <p>{ lang?.about_text_1 }</p>
                    <p>{ lang?.about_text_2 }</p>
                    <p>{ lang?.about_text_3 }</p>
                    <p>{ lang?.about_text_4 }</p>
                  </div>
                </div>

                <h2 className={styles['title-h2']}>{ lang?.about_stat_title }</h2>

                <div className={styles['grid-datas']}>
                  <div>
                    <span className={styles['stat-key']}>3.2M+</span>
                    <span className={styles['stat-value']}>{ lang?.about_stat_addresses_created }</span>
                  </div>
                  <div>
                    <span className={styles['stat-key']}>22k+</span>
                    <span className={styles['stat-value']}>{ lang?.about_stat_users }</span>
                  </div>
                  <div>
                    <span className={styles['stat-key']}>20k+</span>
                    <span className={styles['stat-value']}>{ lang?.about_stat_merchants }</span>
                  </div>
                  <div>
                    <span className={styles['stat-key']}>100M+</span>
                    <span className={styles['stat-value']}>{ lang?.about_stat_requests }</span>
                  </div>
                </div>

                <h2 className={styles['title-h2']}>{ lang?.about_accepted }</h2>

                <div className={styles['currencies-flex']}>

                  {currencies.length > 0 && currencies[0].currencies.map((item) => {
                    if (item.image) {
                      return (
                        <div key={item.symbol} className={styles['main-text-symbol-container']}>
                          <div className={styles['main-text-symbol-left']}><img src={require("../../" + item.image)} alt="" className={styles['img-currency']}/></div>
                          <div className={styles['main-text-symbol-right']}>{ item.symbol }</div>
                        </div>
                      )
                    }
                  })}

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}