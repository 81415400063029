import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { register, finishRegister } from '../../http/userAPI';
import { ActionButton, Input, PasswordInput, Select } from '../../ui-kit/components';
import styles from './SignUp.module.scss';
import { MAIN_ROUTER } from '../../utils/consts'
import { actionSetIsAuth, actionSetUserData } from '../../store/actionCreators/userActionCreator';
import { alertSuccess, alertDanger } from '../../utils/function';
import { getCountries } from '../../http/siteAPI';
import { useEffect } from 'react';

export const SignUp = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.site.lang)
  const language_id = useSelector((state) => state.site.language_id)
  const isAuth = useSelector(store => store.user.isAuth)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')


  const finishSignUp = async () => {

    if (code.length != 4) {
      alertDanger(dispatch,lang['login_signup_register_code'])
      return
    }
    
    if (isAuth) {
      navigate(MAIN_ROUTER)
    }

    try {

      let data = await finishRegister(
        email, 
        password, 
        confirm_password, 
        language_id, 
        code
      )

      dispatch(actionSetIsAuth(true))
      dispatch(actionSetUserData(data)) 

      navigate(MAIN_ROUTER)

    } catch(e) {
        
      alertDanger(dispatch,e.response.data.message)

    }

  }

  const clickSignUp = async () => {

    if (isAuth) {
      navigate(MAIN_ROUTER)
    }
    
    try {

      let data = await register(
        email, 
        password, 
        confirm_password, 
        language_id
      )

      setStep(2)

    } catch(e) {
        
      alertDanger(dispatch,e.response.data.message)

    }

  }

  return (
    <div className={styles.body}>
      <div className={styles.form}>
        <h1 className={styles.title}>{ lang['registration_title'] }</h1>


        { step == 1 && (
          <>
            <Input autocomplete="off" className={styles.input} setInputValue={(val) => setEmail(val)} label='E-mail' dynamicLabel />
            <PasswordInput autocomplete="off" className={styles.input} setInputValue={(val) => setPassword(val)} label={ lang['registration_pass'] } />
            <PasswordInput autocomplete="off" className={styles.input} setInputValue={(val) => setConfirmPassword(val)} label={ lang['registration_pass_confirm'] } dynamicLabel />
            <div className={styles.link_container}>
              <Link className={styles.link} to='/sign-in'>
                { lang['registration_have_account'] }
              </Link>
            </div>
          </>
        ) }

        { step == 2 && (
          <>       
            <div className={styles['code-title']}>{ lang['login_page_code_title'] }</div>
            <div style={{marginBottom: 10, marginTop: 10}}>
              <div><Input autocomplete="off" className={styles.input} setInputValue={(val) => setCode(val)} label={ lang['login_page_code_label'] } dynamicLabel /></div>
            </div>
          </>
        ) }

        <ActionButton className={styles.button} onClick={() => step == 1 ? clickSignUp() : finishSignUp()}>{ step == 1 ? lang['start_now'] : lang['register_finish'] }</ActionButton>

        <p className={styles.disclaimer}>
          { lang['registration_if_click'] }{' '}
          <Link className={styles.disclaimer__link} to='/page/user-agreement'>
            { lang['login_agreement'] }
          </Link>{' '}
          { lang['login_and'] }{' '}
          <Link className={styles.disclaimer__link} to='/page/privacy-policy'>
            { lang['login_policy'] }
          </Link>
        </p>

      </div>
    </div>
  );
};
