import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CREATE_PROJECT_ROUTER } from '../../utils/consts';
import styles from './Projects.module.scss';

export const Projects = () => {

  const projects = useSelector((state) => state.site.projects)
  const lang = useSelector((state) => state.site.lang)

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>{ lang['project_title'] }</div>
      

      <div className={styles['project-list']}>
        <div className={styles['project-title-container']}>
          <div className={styles['project-title']}>{ lang['project_list'] }</div>
          <Link to={CREATE_PROJECT_ROUTER}>
            <div className={styles['project-button']}>+ { lang['project_create'] }</div>
          </Link>
        </div>
      </div>

      { projects.map((p) => {
        return (
          <div className={styles['project-list']}>
            <div className={styles['project-title-container']}>
              <div className={styles['project-title-item']}>{ p.name }</div>
              <Link to={'/project/' + p.id}>
                <div className={styles['project-button-item']}>{ lang['project_edit'] }</div>
              </Link>
            </div>

            <div className={styles['project-title-item-under']}>{ lang['project_type'] }: { p.code }</div>

            <div className={styles['project-title-item-under']}>{ lang['project_desc'] }: { p.description }</div>
            
            <div className={styles['api-block']}>
              <div className={styles['item-input-title-api']}>{ lang['project_shop_id'] }: { p.shop_id }</div>
            </div>
            
          </div>
        )
      }) }

    </div>
  )
}