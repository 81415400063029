import { useSelector } from 'react-redux';
import { WidgetBalance } from '../../components/WidgetBalance/WidgetBalance';
import { WidgetHistoryPayment } from '../../components/WidgetHistoryPayment';
import styles from './Balance.module.scss';

export const Balance = () => {

  const lang = useSelector((state) => state.site.lang)

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['dashboard-title']}>{ lang['widget_balance_title'] }</div>

      <WidgetBalance/>

      <WidgetHistoryPayment/>

    </div>
  )
}