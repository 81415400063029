import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SIGNIN_ROUTER } from '../../utils/consts';

import styles from './Api.module.scss';
import { useState } from 'react';
import { getCurrencies } from '../../http/siteAPI';
import { useEffect } from 'react';

export const Api = () => {

  const lang = useSelector((state) => state.site.lang)
  const [currencies, setCurrencies] = useState([])
  const [loading, setLoading] = useState(true)

  const _load_async = async() => {
    let currencies_data = await getCurrencies()
    setCurrencies(currencies_data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  useEffect(() => {
    _load_async()
  },[])

  return (
    <div>
      <div className={styles['main-container']}>
        <div className={styles['main-left']}>
          <div className={styles['main-text-1']}>{ lang['api_title'] }</div>

          <div className={styles['main-text-4']}>{ lang['api_desc_1'] }</div>
          <div className={styles['main-text-4']}>{ lang['api_desc_2'] }</div>
          
        

          <div className={styles['main-button-container']}>
            <Link to={SIGNIN_ROUTER}>
              <div className={styles['main-button']}>{ lang['main_button_text'] }</div>
            </Link>
          </div>

        </div>
        <div className={styles['main-right']}>
          <img src={require('../../ui-kit/assets/develop-a-rest-api-to-your-mobile-application-rOPxKaxYDI.jpg')} alt="" className={styles['main-image']}/>
        </div>
      </div>


      <div className={styles['text']}>

        <h2 className={styles['h2']}>{ lang['api_desc_3'] }</h2>

        <p>{ lang['api_desc_4'] }</p>
        <p>{ lang['api_desc_5'] }</p>

        <ul className={styles['ul']}>
          <li>{ lang['api_desc_6'] }</li>
          <li>{ lang['api_desc_7'] }</li>
          <li>{ lang['api_desc_8'] }</li>
        </ul>

      </div>

      <div className={styles['text']}>

        <h2 className={styles['h2']}>{ lang['api_desc_9'] }</h2>

        <p>{ lang['api_desc_10'] }</p>

        <br/>

        <h3 className={styles['h3']}>{ lang['api_desc_11'] }</h3>
        
        <p>{ lang['api_desc_12'] }</p>

        <br/>
        
        <p className={styles['title-api']}><strong>{ lang['api_desc_13'] }</strong></p>
        <p>GET /api/shop/invoice/get</p>
        <p>{ lang['api_desc_14'] }</p>
        <p>{ lang['api_desc_15'] }</p>

        <br/>

        <p className={styles['title-api']}><strong>{ lang['api_desc_16'] }</strong></p>
        <p>GET /api/shop/invoice/get-by-cpu</p>
        <p>{ lang['api_desc_17'] }</p>
        <p>{ lang['api_desc_18'] }</p>

        <br/>

        <p className={styles['title-api']}><strong>{ lang['api_desc_19'] }</strong></p>
        <p>https://api.localkassa.com/api/shop/invoice/get?id=10</p>
        <p className={styles['title-api']}>{ lang['api_desc_20'] }</p>
        <p>TODO photo</p>

        <br/>

        <h3 className={styles['h3']}>{ lang['api_desc_21'] }</h3>
        <p>POST /api/shop/invoice/create</p>
        <p>{ lang['api_desc_22'] }</p>
        <ul className={styles['ul']}>
          <li>amount - { lang['api_desc_23'] }</li>
          <li>symbol - { lang['api_desc_24'] }</li>
          <li>order_id - { lang['api_desc_25'] }</li>
        </ul>

        <p className={styles['title-api']}>{ lang['api_desc_20'] }</p>
        <p>TODO photo</p>

        <br/>

        <p>{ lang['api_desc_26'] }</p>

        <br/>

        <p className={styles['title-api']}><strong>{ lang['api_desc_27'] }</strong></p>
        <table className={styles['table-currencies']}>

          <tr style={{backgroundColor: 'rgb(50,50,150)', color: 'white'}}>
            <td></td>
            <td style={{color: 'white'}}>{ lang['api_desc_28'] }</td>
            <td style={{color: 'white'}}>{ lang['api_desc_29'] }</td>
          </tr>

          {currencies.length > 0 && currencies[0].currencies.map((item) => {
            if (item.image) {
              return (
                <tr key={item.symbol}>
                  <td><img src={require("../../" + item.image)} alt="" className={styles['img-currency']}/></td>
                  <td>{ item.symbol.toUpperCase().replace(/\s+/g, '_') }</td>
                  <td>{ item.name }</td>
                </tr>
              )
            }
          })}

        </table>

        <br/>

        <h2 className={styles['h2']}>{ lang['api_desc_30'] }</h2>

        <ul className={styles['ul']}>
          <li>{ lang['api_desc_31'] }: https://api.localkassa.com </li>
        </ul>

        <p className={styles['title-api']}>{ lang['api_desc_32'] }</p>

        <ul className={styles['ul']}>
          <li>Content-Type: application/json</li>
          <li>Authorization: Bearer API_KEY|SHOP_ID</li>
        </ul>

        <p>{ lang['api_desc_33'] } <a href="https://localkassa.com/settings">{ lang['api_desc_34'] }</a>.</p>
        <p>{ lang['api_desc_35'] }</p>

        <br/>

        <h2 className={styles['h2']}>{ lang['api_desc_36'] }</h2>

        <p>{ lang['api_desc_37'] }</p>
        
        <p className={styles['title-api']}>{ lang['api_desc_38'] }</p>
        <p>TODO photo</p>
        <p>{ lang['api_desc_39'] }</p>



      </div>

    </div>
  )
}