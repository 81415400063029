import { useState, useRef } from 'react';
import classNames from 'classnames';

import { Arrow, Card } from '../../ui-kit/components';
import { useClickOutside, useWindowSize } from '../../hooks';
import { LanguageMenuIcon } from './LanguageMenuIcon';

import RU from './assets/RU.svg';
import EN from './assets/EN.svg';
import FR from './assets/FR.svg';
import DE from './assets/DE.svg';
import ES from './assets/ES.svg';
import TR from './assets/TR.svg';

import styles from './LanguageMenu.module.scss';
import { getTranslation } from '../../http/siteAPI';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetLang, actionSetLangSelected, actionSetLanguageId } from '../../store/actionCreators/siteActionCreator';
import { useSelect } from '@mui/base';

export const LanguageMenu = ({ type } ) => {

  const [active, setActive] = useState(false);
  const { isMobile } = useWindowSize();
  const languageMenuRef = useRef(null);
  const dispatch = useDispatch()
  
  type = type === undefined ? 'main' : type

  const lang_selected = useSelector((state) => state.site.lang_selected)
  const languages = useSelector((state) => state.site.languages)
  const lang = useSelector((state) => state.site.lang)

  const handleButtonClick = () => {
    setActive(state => !state);
  };

  console.log(languages)

  const handleOptionClick = async (value) => {
    let lang = await getTranslation(value.short)
    let language_id = value.id
    dispatch(actionSetLanguageId(language_id))
    dispatch(actionSetLangSelected(value.short))
    dispatch(actionSetLang(lang))

    localStorage.setItem('language_id', language_id)
    localStorage.setItem('lang_selected', value.short)

  };

  const onClickOutside = () => {
    setActive(false);
  };

  useClickOutside(onClickOutside, languageMenuRef);

  if (languages.length == 0) {
    return <></>
  }

  return !isMobile ? (
    <div className={styles.wrapper} ref={languageMenuRef}>
  
      { type == 'main' ? ( 
        <div className={styles.button} onClick={handleButtonClick}>
          <LanguageMenuIcon
            className={classNames(styles.icon, {
              [styles['icon--active']]: active,
            })}
          />
          <Arrow active={active} />
        </div>
      ) : (
        <div className={styles.button} style={{paddingLeft: 15}} onClick={handleButtonClick}>
          <LanguageMenuIcon
            className={classNames(styles.icon, {
              [styles['icon--active']]: active,
            })}
          />

          <span style={{paddingLeft: 5, paddingRight: 5, fontSize: 14}}>{ lang['side_bar_change_language'] }</span>

          <Arrow active={active} />
        </div>
      ) }


      {active && (

        type == 'main' ? (
          <Card className={styles.body}>
            {languages.map(option => (
              <div
                className={classNames(styles.option, {
                  [styles['option--active']]: lang_selected === option.short,
                })}
                onClick={() => handleOptionClick(option)}
              >
                <img className={styles.option__icon} src={require('./assets/' + option.short + '.svg')} alt={option.name} />
                {option.name}
              </div>
            ))}
          </Card>
        ) : (
          <div>
            {languages.map(option => (
              <div
                style={{
                  paddingLeft: 50,
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontSize: 14,
                  cursor: 'pointer',
                }}
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </div>
            ))}
          </div>
        )

      )}

    </div>
  ) : (
    <div className={styles.wrapper} ref={languageMenuRef}>

      <div className={styles.button} onClick={handleButtonClick}>
        <LanguageMenuIcon
          className={classNames(styles.icon_mobile, {
            [styles['icon--active']]: active,
          })}
        />
        <Arrow active={active} />
      </div>
      {active && (
        <Card className={styles.body}>
          {languages.map(option => (
            <div
              className={classNames(styles.option_mobile, {
                [styles['option--active']]: lang_selected === option.lang.slice(0, 2),
              })}
              onClick={() => handleOptionClick(option)}
            >
              <img className={styles.option_mobile__icon} src={option.icon} alt={option.text} />
              {option.text}
            </div>
          ))}
        </Card>
      )}

    </div>
  );
};
