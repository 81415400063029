import { useEffect, useState } from "react"

export const Timer = ({ sec }) => {

  const [ h, setH ] = useState('00')
  const [ m, setM ] = useState('00')
  const [ s, setS ] = useState('00')

  let interval = false

  useEffect(() => {
    if (!interval) {
      sec = Number(sec);
      if (!isNaN(sec)) {
        interval = setInterval(() => {
          sec--
          if (sec >= 0) {
            secondsToHms(sec)
          } else {
            clearInterval(interval)
          }
        }, 1000)
      }
    }
  }, [sec])

  const secondsToHms = (d) => {

    d = Number(d);

    if (!isNaN(d)) {

      let hh = Math.floor(d / 3600);
      let mm = Math.floor(d % 3600 / 60);
      let ss = Math.floor(d % 3600 % 60);

      hh = hh < 10 ? "0" + hh : hh
      mm = mm < 10 ? "0" + mm : mm
      ss = ss < 10 ? "0" + ss : ss

      setH(hh)
      setM(mm)
      setS(ss)

    }

  }

  return (
    <>{ h }:{ m }:{ s }</>
  )
}