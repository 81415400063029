import {
    ACTION_SET_IS_AUTH,
    ACTION_SET_USER_DATA,
    ACTION_SET_IS_ADMIN,
} from '../actions/UserAction'

const initialState = {
    user: {},
    isAuth: false,
    isAdmin: false,
};


const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_SET_IS_ADMIN: return { ...state, isAdmin: action.payload };
        case ACTION_SET_IS_AUTH: return { ...state, isAuth: action.payload };
        case ACTION_SET_USER_DATA: return { ...state, user: action.payload };
        default: return state;
    }
}

export default userReducer