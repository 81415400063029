import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Menu.module.scss';

export const Menu = ({ active, url, icon, name, menus }) => {

  const location = useLocation()
  const pathname = location.pathname

  const [show, setShow] = useState(false)

  useEffect(() => {
    menus.map((m) => {
      let activeSub = m.active.filter((i) => i == pathname).length > 0 ? true : false
      if (activeSub) {
        setShow(true)
      }

      if (active) setShow(true)
    })
  }, [menus])

  return (
    <>  
      <div className={active ? styles['menu-item-active'] : styles['menu-item']}>
        <div className={styles['menu-sub-cont']}>
          <Link to={url}><span class={ icon }></span> &nbsp; { name }</Link> 
          { menus.length > 0 && <span onClick={() => setShow(!show)} class={ show ? "fa fa-angle-up" : "fa fa-angle-down" }></span> }
        </div>
      </div>

      { show && 
        <div className={styles['sub-menu']}>
          {menus.map((m) => {
            let activeSub = m.active.filter((i) => i == pathname).length > 0 ? true : false
            return (
              <Link to={m.url}>
                <div className={activeSub ? styles['menu-item-sub-active'] : styles['menu-item-sub']}>{ m.name }</div>
              </Link>
            )
          })}
        </div>
      }
    </>
  )
}