import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBills } from '../../http/siteAPI'
import { actionSetBills, actionSetBillSearch } from '../../store/actionCreators/siteActionCreator'
import { InputText } from '../../ui-kit/components'
import styles from './Bills.module.scss'

export const Bills = () => {

  const dispatch = useDispatch()

  const bills = useSelector((state) => state.site.bills)
  const bill_search = useSelector((state) => state.site.bill_search)
  const currencies = useSelector((state) => state.site.currencies)
  const lang = useSelector((state) => state.site.lang)

  const __load_async = async() => {
    if (bill_search !== undefined) {
      let temp_bills = await getBills(bill_search)
      dispatch(actionSetBills(temp_bills))
    }
  }

  const billStatues = {
    new: lang['bill_status_waiting_payment'],
    success: lang['bill_status_paid'],
    success_process: lang['bill_status_paid'],
    send_transition: lang['bill_status_paid'],
    send_comission: lang['bill_status_paid'],
    compare: lang['bill_status_paid'],
    canceled: lang['bill_status_canceled'],
    partial: lang['bill_status_paid_partial'],
  }

  const setSearch = (val) => {
    dispatch(actionSetBillSearch(val))
  }

  useEffect(() => {
    __load_async()
  },[bill_search]) 
  
  return (
    <div className={styles['container']}>
      <div className={styles['flex-container']}>
        <div className={styles['block-title']}>{ lang['bill_widget_history'] }</div>
        <div>
          <InputText label={ lang['bill_widget_search'] } defvalue={bill_search} setInputValue={setSearch} />
        </div>
      </div>
      <div className={styles['table-bill-container']}>
        <table className={styles['table-bill']}>

          <thead>

            <tr className={styles['table-bill-thead']}>
              <td>{ lang['bill_widget_status'] }</td>
              <td>{ lang['bill_widget_amount'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['bill_widget_valute'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['bill_widget_bill_id'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['bill_widget_date_created'] }</td>
              <td style={{textAlign: 'center'}}>{ lang['bill_widget_link'] }</td>
            </tr>

          </thead>

          <tbody>

            { bills.map((bill) => {

              let image = 'ui-kit/assets/dolary.png'
              if (bill.symbol !== "USD") {
                currencies.map((tp) => {
                  tp.currencies.map((c) => {
                    if (c.symbol == bill.symbol) {
                      image = c.image
                    }
                  })
                })
              }

              return (
                <tr key={ bill.id }>
                  <td>
                    <div className={styles[bill.status]}>{ billStatues[bill.status] }</div>
                    { (
                      bill.status == 'success_process' ||
                      bill.status == 'send_transition' ||
                      bill.status == 'send_comission' ||
                      bill.status == 'compare' 
                    ) && (
                      <div style={{color: 'red', marginTop: 8, textAlign: 'center'}}>{ lang['bill_widget_waiting_confirmations'] }</div>
                    )}
                  </td>
                  <td>{ bill.amount } { bill.symbol }</td>
                  <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                    <img src={require("../../" + image)} alt="" className={styles['img-currency']}/>
                  </td>
                  <td style={{textAlign: 'center'}}>{ bill.cpu }</td>
                  <td style={{textAlign: 'center'}}>{ bill.created_on }</td>
                  <td style={{textAlign: 'center'}}><a target="_blank" style={{color: 'blue'}} href={ process.env.REACT_APP_URL + "/pay/" + bill.cpu } ><span class="fa fa-link"></span></a></td>
                </tr>
              )
            })}

          </tbody>

        </table>

        { bills.length == 0 && <div className={styles['no-bills']}>{ lang['bill_widget_no_bills'] }</div>}
      </div>
    </div>
  )
}